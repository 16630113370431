.root {
  
  text-align: left;
  & [class~="input-group"] {
    margin: 0;
  }
  &::after {
    content: "" !important;
  }

  
}

.centeredInputGroup {
  align-items: center;
}
