/* Base styles */
.headerContainer {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  p{
    font-weight: 400;
  }
}

.jobContainer {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.jobHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobInfo {
  flex: 1;
}

.jobTitle {
  font-weight: bold;
  font-size: 1.2em;
}

.jobSubTitle {
  font-size: 1em;
  color: #666;
}

.jobDetails {
  display: flex;
  align-items: center;
}

.jobDetails div {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.candidateCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #332F2D;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 1em;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.buttons button {
  margin: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .jobHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .candidateCircle {
    display: none;
  }

  .jobDetails {
    display: none;
  }

  .jobTitle {
    font-size: 1em;
  }

  .jobSubTitle {
    font-size: 0.9em;
  }

  .buttons {
    flex-direction: row;
    justify-content: space-between;
    // width: 100%;
  }

  // .buttons button {
  //   width: 48%;
  // }
}

@media (max-width: 480px) {
  .jobTitle {
    font-size: 0.9em;
  }

  .jobSubTitle {
    font-size: 0.8em;
  }



  // .buttons button {
  //   width: 100%;
  //   margin: 5px 0;
  // }
}

// .pageHeader {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   [class~='footer'] {
//     @media only screen and (max-width: 767px) {
//       padding: 0;
//     }
//   }
//   @media only screen and (max-width: 991px) {
//     min-height: calc(100vh - 59px);
//     &::before {
//       background-color: #fff !important;
//     }
//   }
// }

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  padding-top: 90px;
}

@media only screen and (max-width: 991px) {
  .container {
    padding-top: 70px;
  }
}

// .containerhead {
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   padding-bottom: 15px;
// }

// @media only screen and (max-width: 991px) {
//   .containerhead {
//     max-width: unset !important;
//     padding-bottom: 80px;
//   }
// }

// .content {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: unset;
//   margin-top: 110px !important;
//   width: 100%;
//   @media only screen and (max-width: 991px) {
//     margin-top: 90px !important;
//   }
//   @media only screen and (max-width: 500px) {
//     margin-top: 60px !important;
//   }
// }

// .tableHeader {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: space-between;
//   margin: 12px 0;
//   gap: 10px 20px;
//   @media only screen and (max-width: 500px) {
//     justify-content: center;
//     flex-direction: column;
//   }
// }

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
}

// .tableContainer {
//   width: 100%;
//   max-height: calc(100vh - 318px);
//   overflow: auto;
//   [class~='table-responsive'] {
//     overflow-x: unset;
//   }
//   @media only screen and (max-width: 991px) {
//     max-height: calc(100vh - 159px);
//   }
// }

// .table {
//   background-color: #fff;
//   margin-bottom: 0;
// }

// .titleSubTable {
//   font-size: 1em !important;
//   font-weight: 600 !important;
// }

// .titleSubTableHunt {
//   font-size: 1em !important;
//   font-weight: 400 !important;
// }

// .hiddenOnMobile {
//   @media only screen and (max-width: 767px) {
//     display: none;
//   }
// }

// // disabled due to TASK 1103 Centre align column for consistency. Although this seems to fix the problem, it might have unintended results. I couldn't find another way to fix it.
// // .itemTitle {
// //   display: none;
// //   @media only screen and (min-width: 576px) {
// //     display: block;
// //   }
// // }

// .itemCompany {
//   display: none;
//   @media only screen and (min-width: 576px) {
//     display: block;
//   }
// }

// .itemTitleToggle {
//   // display: block;
//   @media only screen and (max-width: 575px) {
//     // display: block;
//     cursor: pointer;
//   }
// }

// .buttonContainer {
//   @media only screen and (max-width: 441px) {
//     text-align: center !important;
//   }
// }

// .btnContent {
//   display: inline-flex;
//   align-items: center;
//   padding-left: 10px;
//   padding-right: 10px;
//   margin: 5px !important;
//   z-index: 20;
//   @media only screen and (max-width: 575px) {
//     padding-left: 6px;
//     padding-right: 6px;
//   }
// }

// .btnAssist {
//   background-color: #f2e822;
//   color: #2c2c2c;
//   &:hover,
//   &:focus {
//     color: #2c2c2c;
//     background-color: #fff75c;
//   }
// }
// .btnAssist2 {
//   background-color: #BF42E8;
//   color: #f1f1f1;
//   &:hover,
//   &:focus {
//     color: #f1f1f1;
//     background-color: #BF42E8;
//   }
// }


// .commonSelect {
//   margin: 0;
// }

// .subTable {
//   background-color: #fff;
//   margin-bottom: 0;
// }

// .subTableHeader {
//   background-color: #f1f1f1;
//   @media only screen and (max-width: 767px) {
//     display: none !important;
//   }
// }



// .subTableBody {
//   background-color: #f1f1f1;
// }

// .subTableAssist {
//   background-color: #fff;
//   margin-bottom: 0;
// }

// .subTableHeaderAssist {
//   background-color: #f2e822;
//   // @media only screen and (max-width: 767px) {
//   //   display: none !important;
//   // }
// }

// .iconRotateWrapper {
//   cursor: pointer;
//   @media only screen and (max-width: 575px) {
//     display: none !important;
//   }
// }

// .wrapper {
//   padding-top: 150px;
// }

// .rotate {
//   -moz-transition: all 0.2s linear;
//   -webkit-transition: all 0.2s linear;
//   transition: all 0.2s linear;
// }

// @media (max-width: 768px) {
//   tr {
//     cursor: pointer;
//     /* add 'mobile-view' class */
//   }
// }

// .rotate.down {
//   -ms-transform: rotate(180deg);
//   -moz-transform: rotate(180deg);
//   -webkit-transform: rotate(180deg);
//   transform: rotate(180deg);
// }

// .uncontrolledDropdownWrapper {
//   overflow: hidden;
// }

// .uncontrolledDropdown {
//   list-style-type: none;
// }

// .dropdownWrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0;
//   margin: 0;
//   color: #000000;
//   &:focus,
//   &:hover {
//     color: #000000;
//   }
// }

// .dropdownContent {
//   margin: 0;
//   font-size: 14px;
//   color: #000000;
//   font-weight: 400;
// }

// .dropdownMenu {
//   text-align: center !important;
//   transition: none !important;
//   &::before {
//     display: none !important;
//   }
// }

// .dropdownMenu1 {
//   @media only screen and (min-width: 992px) {
//     left: 50px !important;
//   }
// }

// .pdfWrapper {
//   margin-top: -30px;
//   width: 595px;
// }

// .profileName {
//   margin-bottom: 4px;
//   font-size: 1.5em;
// }

// .profileTitle {
//   background-color: #f2e822;
//   margin: 0;
//   font-weight: 800;
//   font-size: 1.4em;
// }

// .labelTitle {
//   font-weight: 800;
// }

// .sectionTitle {
//   background-color: #f2e822;
//   margin-top: 0;
//   font-size: 1.1em;
// }

// .experienceDescription {
//   line-height: 1.61em;
//   font-weight: 600;
//   font-size: 1em;
// }

// .paragraphFontSize {
//   font-size: 0.8em !important;
// }

// .bold {
//   font-weight: 600 !important;
// }
