@import '../../variables2';
@import '../../components/Mixins/now-ui-dashboard/mixins';


.navbar {
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  // background-color: transparent!important;
  min-height: 53px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

  .animation-transition-general {
    @include transition($general-transition-time, $transition-ease);
  }

  

  .user 
  
  .info {
    [data-toggle="collapse"] ~ div > ul > li > a {
      vertical-align: middle;

      span {
        display: inline-block;
        @extend .animation-transition-general;
      }

      .sidebar-normal {
        margin: 0;
        position: relative;
        transform: translateX(0px);
        opacity: 1;
        white-space: nowrap;
        display: block;
        line-height: 23px;
        z-index: 1;
      }

      .sidebar-mini-icon {
        text-transform: uppercase;
        width: 34px;
        margin-right: 10px;
        margin-left: 0px;
        font-size: 12px;
        text-align: center;
        line-height: 25px;
        position: relative;
        float: left;
        z-index: 1;
        display: inherit;
        line-height: 24px;
        color: $opacity-5;
      }

      i {
        font-size: 17px;
        line-height: 20px;
        width: 26px;
      }
    }
  }

  @media (max-width: 993px) {
    .mobileuser {
      display: block;
    }
  }
  
  @media (min-width: 994px) {
    .mobileuser {
      display: none;
    }
  }

  
  [data-toggle="collapse"] ~ div > ul > li.active > a {
    .sidebar-mini-icon {
      color: $primary-color;
    }
  }

  .navbar-minimize {
    position: absolute;
    right: 18px;
    top: 2px;
    opacity: 1;

    .btn {
      &,
      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
      }
    }

    @extend .animation-transition-general;
  }

  .dropdownmenu {

    span {
    color: black;
    }

    .dropdownitem {
      color: black!important;
      }
  }

  .user {
    // padding-bottom: 20px;
    // margin: 20px auto 0;
    // position: relative;



    .dropdowntoggle {
      display: flex;
      align-items: center;
    
      .photo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; /* Add relative positioning */



      }
      span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 100;
      }
    }

    .notifications {
      width: 34px;
      height: 34px;
      overflow: visible;
      float: left;
      z-index: 5;
      border-radius: 50%;
      margin-left: 23px;
      box-shadow: $box-shadow-raised;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; 
      @extend .animation-transition-general;
    }

    .photo {
      width: 35px;
      height: 35px;
      overflow: hidden;
      float: left;
      z-index: 5;
      border-radius: 50%;
      margin-left: 23px;
      // box-shadow: $box-shadow-raised;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; 
      @extend .animation-transition-general;
    
      

      img {
        width: 105%;  // Adjust the width to make it slightly smaller
        height: 105%; // Adjust the height to make it slightly smaller
        border: none;
        padding: 0;
        object-fit: cover; // Ensures the image covers the container without stretching
      }
    }

    a {
      color: $black-color;
      text-decoration: none;
      padding: $padding-base-vertical 15px;
      white-space: nowrap;
      @extend .animation-transition-general;
    }

    .info {
      > a {
        display: block;
        line-height: 18px;

        > span {
          @extend .animation-transition-general;
          display: block;
          position: relative;
          opacity: 1;
        }
      }

      .caret {
        position: absolute;
        top: 8px;
        right: 18px;
      }
    }
  }
  
    
    .brand {
      font-family: 'Hippopotamus Apocalypse';

    }
    a {
      vertical-align: middle;
  
      &:not(.btn):not(.dropdown-item) {
        color: $black-color;
      }
  
      &.dropdown-item {
        color: $default-color;
      }
    }
  
    &.bg-white {
      .input-group .form-control,
      .input-group.no-border .form-control {
        color: $default-color;
  
        @include placeholder() {
          color: $default-color;
        }
      }
      .input-group-prepend .input-group-text i,
      .input-group-append .input-group-text i {
        color: $default-color;
        opacity: 0.5;
      }
    }
  
    .form-group,
    .input-group {
      margin: 0;
      margin-left: -3px;
      margin-right: 5px;
  
      .form-group-addon,
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        color: $black-color;
  
        i {
          opacity: 1;
        }
      }
  
      &.no-border {
        .form-control {
          color: $black-color;
  
          @include placeholder() {
            color: $black-color;
          }
        }
      }
    }
  
    p {
      display: inline-block;
      margin: 0;
      line-height: 1.8em;
      font-size: 1em;
      font-weight: 400;
    }
  
    &.navbar-absolute {
      position: absolute;
      width: 100%;
      padding-top: 10px;
      z-index: 1029;
    }
  
    .documentation & {
      &.fixed-top {
        left: 0;
        width: initial;
      }
    }
  
    .navbar-wrapper {
      display: inline-flex;
      align-items: center;
  
      .navbar-minimize {
        padding-right: 10px;
  
        .btn {
          margin: 0;
        }
      }
      
  
      .navbar-toggle {
        .navbar-toggler {
          padding-left: 0;
        }
  
        &:hover {
          & .navbar-toggler-bar.bar2 {
            width: 22px;
          }
        }
      }
    }
  
    .navbar-nav {
      display: flex;
      align-items: center;
      &.navbar-logo {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 49px;
        top: -4px;
      }
  
      .nav-link.btn {
        padding: $padding-btn-vertical $padding-btn-horizontal;
        &.btn-lg {
          padding: $padding-large-vertical $padding-large-horizontal;
        }
        &.btn-sm {
          padding: $padding-small-vertical $padding-small-horizontal;
        }
      }
  
      .nav-link {
        text-transform: uppercase;
        font-size: $font-size-mini;
        padding: $padding-base-vertical $padding-base-horizontal;
        line-height: $line-height-nav-link;
        margin-right: 3px;
        display: flex;
        align-items: center;
  
        i.fa + p,
        i.now-ui-icons + p {
          margin-left: 3px;
        }
  
        i.fa,
        i.now-ui-icons {
          font-size: 18px;
          position: relative;
          top: 3px;
          text-align: center;
          width: 21px;
        }
  
        i.now-ui-icons {
          top: 4px;
          font-size: 16px;
        }
  
        &.profile-photo {
          .profile-photo-small {
            width: 27px;
            height: 27px;
          }
        }
  
        &.disabled {
          opacity: 0.5;
          color: $black-color;
        }
      }
  
      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active {
        background-color: $opacity-2;
        border-radius: $border-radius-small;
        color: $black-color;
      }
    }
  
    .logo-container {
      width: 27px;
      height: 27px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 50%;
      border: 1px solid transparent;
    }
  
    .navbar-brand {
      text-transform: uppercase;
      font-size: $font-size-small;
      padding-top: $padding-base-vertical;
      padding-bottom: $padding-base-vertical;
      line-height: $line-height-nav-link;
    }
  
    .navbar-toggler {
      display: none;
      border: none;
      width: 37px;
      height: 27px;
      vertical-align: middle;
      outline: 0;
      background-color: transparent;
      cursor: pointer;
      @media (max-width: 991px) {
        display: block;
      }
      & .navbar-toggler-bar {
        background-color: $black-color !important; // Change this to black
      }
  
      & .navbar-toggler-bar.navbar-kebab {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin: 0 auto;
        background-color: $black-color !important; 
      }
    }
  
    .button-dropdown {
      .navbar-toggler-bar:nth-child(2) {
        width: 17px;
      }
    }
  
    &.navbar-transparent {
      // background-color: $transparent-bg !important;
      box-shadow: none;
      color: $black-color;
    }
  
    &.bg-white:not(.navbar-transparent) {
      a:not(.dropdown-item):not(.btn) {
        color: $default-color;
  
        &.disabled {
          opacity: 0.5;
          color: $default-color;
        }
      }
  
      .button-bar {
        background: $default-color;
      }
  
      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active {
        background-color: $opacity-gray-8;
        color: $default-color;
      }
  
      .logo-container {
        border: 1px solid $default-color;
      }
    }
  }
  
  .bg-default {
    background-color: $default-color !important;
  }
  
  .bg-primary {
    background-color: $primary-color !important;
  }
  
  .bg-info {
    background-color: $info-color !important;
  }
  
  .bg-success {
    background-color: $success-color !important;
  }
  
  .bg-danger {
    background-color: $danger-color !important;
  }
  
  .bg-warning {
    background-color: $warning-color !important;
  }
  
  .bg-white {
    background-color: $white-color !important;
  }

  .nav-open {
    .main-panel {
      right: 0;
      transform: translateX(260px);
    }

    .sidebar {
      transform: translateX(0px);
      box-shadow: $sidebar-box-shadow;
    }

    body {
      position: relative;
      overflow-x: hidden;
    }

    .menu-on-right {
      .main-panel {
        transform: translateX(-260px);
      }

      .navbar-collapse,
      .sidebar {
        transform: translateX(0px);
      }

      .navbar-translate {
        transform: translateX(-300px);
      }

      #bodyClick {
        right: 260px;
        left: auto;
      }
    }
  }

@media screen and (max-width: 991px) {
    .profile-photo .profile-photo-small {
      margin-left: -2px;
    }
  
    .button-dropdown {
      display: none;
    }
  
    [data-notify="container"].alert {
      min-width: 400px;
    }
  
    #minimizeSidebar {
      display: none;
    }
  
    .timeline > li > .timeline-panel {
      width: 86% !important;
      float: right !important;
    }
  
    .timeline:before,
    .timeline > li > .timeline-badge {
      left: 5% !important;
    }
  
    .timeline > li > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto !important;
    }
  
    .timeline > li > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto !important;
    }
  
    .timeline > li:not(.timeline-inverted) > .timeline-panel:after,
    .timeline > li:not(.timeline-inverted) > .timeline-panel:before {
      @include rotate-180();
    }
  
    .navbar {
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
        
      }
  
      .navbar-collapse {
        .input-group {
          margin: 0;
          margin-top: 5px;
        }
      }
  
      .navbar-nav {
        
        .nav-item:first-child {
          margin-top: 10px;
        }
        .nav-item:not(:last-child) {
          margin-bottom: 10px;
        }
      }
  
      .dropdown.show .dropdown-menu {
        display: block;
      }
  
      .dropdown .dropdown-menu {
        display: none;
      }
  
      .dropdown.show .dropdown-menu,
      .dropdown .dropdown-menu {
        background-color: transparent;
        border: 0;
        transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: auto;
        margin: 0px 1rem;
        margin-top: 0px;
  
        &:before {
          display: none;
        }
      }
  
      .dropdown-menu .dropdown-item:focus,
      .dropdown-menu .dropdown-item:hover {
        color: $white-color;
      }
  
      &.bg-white .dropdown-menu .dropdown-item:focus,
      &.bg-white .dropdown-menu .dropdown-item:hover {
        color: $default-color;
      }
  
      &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
        background-color: $default-color;
      }
    }
  
    .wrapper {
      transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  
    .sidebar {
      box-shadow: none;
    }
  
    #bodyClick {
      height: 100%;
      width: 100%;
      position: fixed;
      opacity: 1;
      top: 0;
      right: 0;
      left: 260px;
      content: "";
      z-index: 9999;
      overflow-x: hidden;
      background-color: transparent;
      transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  
    .footer {
      .copyright {
        text-align: right;
      }
    }
  
    .section-nucleo-icons .icons-container {
      margin-top: 65px;
    }
  
    .navbar-nav {
      
      .nav-link {
        i.fa,
        i.now-ui-icons {
          opacity: 0.5;
        }
      }
    }
  
    @include sidebar();
  }
  
  @media screen and (min-width: 992px) {
    .navbar-collapse {
      background: none !important;
    }
  
    .navbar .navbar-toggle {
      display: none;
    }
  
    // .navbar.fixed-top{
    //     width: $sidebar-width;
    //     right: 0;
    //     left: auto;
    // }
  
    .navbar-nav {
      
      .nav-link {
        &.profile-photo {
          padding: 0;
          margin: 7px $padding-base-horizontal;
        }
      }
    }
  
    .section-nucleo-icons .icons-container {
      margin: 0 0 0 auto;
    }
  
    .dropdown-menu .dropdown-item {
      color: inherit;
    }
  
    .footer {
      .copyright {
        float: right;
        padding-right: 15px;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .nav-tabs {
      display: inline-block;
      width: 100%;
      padding-left: 100px;
      padding-right: 100px;
      text-align: center;
  
      .nav-item > .nav-link {
        margin-bottom: 5px;
      }
    }
  
    .user-profile [class*="col-"] {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  
    .card-stats [class*="col-"] .statistics::after {
      display: none;
    }
  
    .main-panel .content {
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .footer {
      nav {
        display: block;
        margin-bottom: 5px;
        float: none;
      }
    }
  
    .landing-page .section-story-overview .image-container:nth-child(2) {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .navbar[class*="navbar-toggleable-"] .container {
      margin-left: 0;
      margin-right: 0;
    }
  
    [data-notify="container"].alert {
      left: 10px !important;
      right: 10px !important;
      width: auto;
    }
  
    .card-contributions .card-stats {
      flex-direction: column;
  
      .bootstrap-switch {
        margin-bottom: 15px;
      }
    }
  
    .footer {
      .copyright {
        text-align: center;
      }
    }
  
    .section-nucleo-icons {
      .icons-container {
        i {
          font-size: 30px;
  
          &:nth-child(6) {
            font-size: 48px;
          }
        }
      }
    }
  
    .page-header {
      .container h6.category-absolute {
        width: 90%;
      }
    }
  }
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @include sidebar();
  
    .navbar-minimize {
      display: none;
    }
  
    .sidebar {
      box-shadow: none;
  
      .nav-open & {
        box-shadow: $sidebar-box-shadow;
      }
    }
  
    .sidebar,
    .main-panel,
    .sidebar-wrapper {
      -webkit-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
      -webkit-overflow-scrolling: touch;
    }
  }


  .nav {
    margin-top: 20px;
    display: block;

    .caret {
      top: 14px;
      position: absolute;
      right: 10px;
    }

    li {
      > a + div .nav li > a {
        margin-top: 7px;
      }

      > a {
        margin: 10px 0px 0;
        border-radius: $btn-round-radius;
        color: $black-color;
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        font-size: $font-size-mini;
        padding: 10px 0px;
        padding-right: 15px;
        line-height: $line-height-nav-link;
      }

      &:first-child > a {
        margin: 0 0px;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        background-color: $opacity-1;
      }

      &:hover:not(.active) > a i,
      &:focus:not(.active) > a i {
        color: $white-color;
      }

      &.active > a:not([data-toggle="collapse"]) {
        background-color: $white-color;
        box-shadow: $box-shadow;
      }

      &.active > a[data-toggle="collapse"] {
        background-color: $opacity-1;
        box-shadow: none;
        color: $white-color;

        i {
          color: $white-color;
        }

        & + div .nav .active a {
          background-color: transparent;
          box-shadow: none;

          &:after {
            content: "";
            position: absolute;
            background-color: $white-color;
            border-radius: 30px;
            box-shadow: $box-shadow;
            color: $primary-color;
            height: 44px;
            width: calc(100% - 5px);
            top: 0;
            left: 2px;
            z-index: 0;
          }
        }
      }
    }
  }



  
  // .navbar-nav .dropdown-toggle {
  //   display: flex;
  //   align-items: center;
  // }
  
  // .navbar-nav .dropdown-toggle i {
  //   margin-right: 5px; /* Adjust as necessary for spacing */
  // }
  
  // .navbar-nav .dropdown-toggle p {
  //   margin: 0;
  //   display: flex;
  //   align-items: center;
  // }


  .navlink {
    display: block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    padding: 0.5rem 1rem;
  
    &:hover {
      text-decoration: none;
      color: $primary-color; // Adjust based on your theme
    }
  }


  .notificationCount {
    position: absolute;
    top: -6px; /* Move it higher */
    right: -10px; /* Move it further right */
    background: red;
    color: white;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 12px;
    font-weight: 500!important;
  }

  .searchResults {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 150px; // Adjust height to fit three items
    overflow-y: auto;
    z-index: 1000;
    margin-top: 5px;
  }
  
  .searchResultItem {
    padding: 10px;
    padding-left: 15px; // Add padding to the left side
    text-decoration: none; // Remove underline from NavLink
    color: inherit; // Ensure the text color is inherited
    display: block; // Make the NavLink block-level for consistent padding
    cursor: pointer;
    transition: background 0.3s;
  
    &:hover {
      background: #f5f5f5;
    }
  
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
  
  .searchResultItem span {
    display: block;
    // font-weight: bold;
    color: #333;
  }
  
  .searchResultItem small {
    display: block;
    color: #888;
  }