@font-face {
  font-family: 'Hippopotamus Apocalypse';
  src: url('~assets/fonts/HippopotamusApocalypse.eot');
  src: url('~assets/fonts/HippopotamusApocalypse.eot?#iefix') format('embedded-opentype'),
  url('~assets/fonts/HippopotamusApocalypse.woff2') format('woff2'),
  url('~assets/fonts/HippopotamusApocalypse.woff') format('woff'),
  url('~assets/fonts/HippopotamusApocalypse.ttf') format('truetype'),
  url('~assets/fonts/HippopotamusApocalypse.svg#HippopotamusApocalypse') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}