.card {
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.cardBody {
  padding: 0 1.25rem;
  overflow: auto;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  text-align: center;
  flex-grow: 1;
  position: relative;
}

.spacer {
  width: 100px; /* Adjust the width to match the button width */
  height: 1px;
}

.content {
  min-height: 571px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.sliderHourlyRate {
  margin: 4px 12px 6px;
}

.textarea {
  > textarea {
    min-height: 100px;
  }
}

.btnDeleteWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btnDelete {
  width: fit-content !important;
}
