@import '../../../_variables.scss';
.wrapper {
  width: 100vw;
  background-color: #f2e822;
  position: fixed;
  bottom: 0;
  z-index: 99999;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  @media only screen and (min-width: 576px) {
    display: none;
  }
}
 
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f2e822;
}

.button {
  flex: 1;
  color: #000000;
  background-color: transparent;
  font-size: 12px;
  border: none;
  //border-right: 1px solid #000000;
  border-radius: 0;
  margin: 5px 0;
  &:last-child {
    border-right: none;
  }
  &:hover {
    // color: #000000 !important;
    background-color: #f2e822;
    box-shadow: 5px 10px;
  }
  &:active {
    // color: #000000 !important;
    background-color: #f2e822;
    box-shadow: 5px 10px;
  }
}

.footerIcon {
  font-size: 1.8em;
}

.messageIcon,
.offersIcon {
  position: relative;
}

.offersDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: -2px;
  left: -2px;
  width: 5px;
  height: 5px;
}

.messageDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: -2px;
  left: -2px;
  width: 5px;
  height: 5px;
}

.footer {
  @media only screen and (max-width: 991px) {
    display: none;
  }
  position: sticky;
  // border: 0.5px outset black;
  left: 0;
  //bottom: 0;
  width: 100%;
  text-align: center;
  }


.footer {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}



.copyright {
  @media screen and (max-width: 767px) {
    text-align: unset !important;
  }
}


.footer{
  padding: 1.125rem 0;
  text-align: center;

  &.footer-default{
      background-color: #f2f2f2;
  }

  &.footer-white{
      background-color: $white-color;
  }

  nav{
      display: inline-block;
      float: left;
  }

  .content{
      text-align: left;
  }

  .nav-link{
      display: inline;
  }

  &.footer-big{
      padding: 30px 0;

      .social-feed i{
          font-size: 20px;
          float: left;
          padding-right: 10px;
          padding-bottom: 30px;
      }

      .gallery-feed img{
          width: 20%;
          margin-right: 5%;
          margin-bottom: 5%;
          float: left;
      }
  }

  .footer-brand{
      float: left;
      font-size: 0.875rem;
      line-height: 44px;
  }



  ul{
      margin-bottom: 0;
      padding: 0;
      list-style: none;

      &:not(.links-vertical){
          line-height: 3.1;
      }

      &.links-vertical{
          line-height: 1.6;

          li{
              display: block;
              margin-left: -5px;
              margin-right: -5px;
              margin-bottom: 0px;

              a{
                  padding: 5px;
                  display: block;
              }
          }
      }

      li{
          display: inline-block;

          a{
              color: inherit;
              padding: 13px $padding-base-vertical;
              font-size: $font-size-small;
              text-transform: uppercase;
              text-decoration: none;

              &:hover{
                  text-decoration: none;
              }
          }
      }
  }

  .social-buttons{
      a,
      .btn{
          margin: 0;
      }
  }

  .pull-center{
      display: inline-block;
      float: none;
  }

  .copyright{
      font-size: $font-size-small;
      line-height: 3.5;
  }

  &:after{
      display: table;
      clear: both;
      content: " ";
  }
}
