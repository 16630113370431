.wrapper {
  position: relative;
  @media only screen and (max-width: 767px) {
    max-height: unset;
    min-height: unset;
  }
}

.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.content {
  margin-top: 100px !important;
  @media only screen and (max-width: 767px) {
    margin: 0 !important;
    margin-bottom: 0 !important;
  }
}

.container {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    padding: 0 !important;
    margin: 0 !important;
    max-width: unset;
    min-height: calc(100vh - 139px);
  }
}

.card {
  max-width: 800px;
  color: #000000;
  padding: 15px 25px;
  margin-bottom: 0;
  overflow: auto;

  @media only screen and (max-width: 767px) {
    padding: 15px 15px 30px;
    min-height: calc(100vh - 139px);
    border-radius: 0;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.addButton {
  // display: none;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  > i {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  &::after {
    content: "";
    width: 0;
    border-top: 1px solid #000000;
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: all 0.25s;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::after {
    width: 100%;
    left: 0;
    right: 0;
    transition: all 0.25s;
  }
}

.awaitingPaymentSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e86c42;
  margin-bottom: 8px;
  padding: 6px 14px;
}

.paidSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #bf42e8;
  padding: 6px 14px;
}

.sectionItem {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
}

.divider {
  height: 1px;
  position: relative;
  margin: 20px auto 0;
  background: #000000;
}

.cardBody {
  padding: 16px 0 0 !important;
}

.tableContainer {
  max-height: calc(100vh - 378px);
  overflow: auto;
}

.table {
  th {
    font-weight: 400 !important;
  }
}

.hideOnMobile {
  @media only screen and (max-width: 575.98px) {
    display: none;
  }
}

.hideOnDesktop {
  @media only screen and (min-width: 576px) {
    display: none;
  }
  display: flex;
  justify-content: center;
}

.approvedStatus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9c9c9c;
}

.paidStatus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3eae19;
}

.statusItem {
  @media only screen and (max-width: 575.98px) {
    width: calc(100% / 6);
  }
}

.clientItem {
  @media only screen and (max-width: 575.98px) {
    width: 50%;
  }
}

.footer {
  @media only screen and (max-width: 650px) {
    display: none;
  }
}
