.pageHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  [class~="footer"] {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  &::before {
    @media only screen and (max-width: 991px) {
      background-color: #fff !important;
    }
  }
}

.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 992px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.wrapper {
  position: relative;
  @media only screen and (max-width: 991px) {
    max-height: unset;
    min-height: unset;
  }
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: unset;
  margin-top: 100px !important;
  width: 100%;
  min-height: calc(100vh - 140px);
  @media only screen and (max-width: 991px) {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.container {
  max-height: calc(100vh - 180px);
  display: flex;
  justify-content: flex-start;
  background-color: #f8f9fa;
  flex-direction: column;
  padding: 20px 28px;
  height: fit-content !important;
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    max-height: unset;
  }
}

.mainTitle {
  color: #000;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 40px;
}

.table {
  max-height: calc(100vh - 180px);
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      th {
        font-weight: 400;
      }
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: calc(100vh - 210px);
  overflow: auto;
  @media only screen and (max-width: 991px) {
    max-height: calc(100vh - 132px);
  }
}

.tableHeaderContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 30px;
  [class~="form-group"] {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 25px 25px 0;
  }
  @media only screen and (max-width: 454px) {
    justify-content: center;
    gap: 10px;
  }
}

.stage {
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;
  }
  &:hover {
    cursor: pointer;
    background-color: #e3e3e3;
    transition: all 0.3s;
  }
}

.upperCase {
  text-transform: uppercase;
}

.sectionTotal {
  color: #000;
  display: flex;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #000;
  padding: 20px 16px;
  justify-content: space-between;
  margin-bottom: 24px;
}

.sectionTotalTitle {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  text-align: left;
}

.sectionTotalNumber {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.dashboardChart {
  width: 100%;
  height: 380px;
}

.leftInfo {
}

.chartLegendWrapper {
  display: flex;
  justify-content: flex-end;
}

.uncontrolledDropdownWrapper {
  overflow: hidden;
}

.uncontrolledDropdown {
  list-style-type: none;
  background-color: #cccccc;
  a,
  p {
    font-size: 12px;
  }
  padding: 8px 16px;
  border-radius: 16px;
  height: fit-content;
  margin-right: 16px;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: #000000;
  &:focus,
  &:hover {
    color: #000000;
  }
}

.dropdownContent {
  margin: 0;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.dropdownMenu {
  text-align: center !important;
  transition: none !important;
  &::before {
    display: none !important;
  }
}

.chartLegend {
  color: #000;
  text-align: left;
  i {
    width: 20px;
    text-align: center;
  }
  p {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
  }
  ul {
    list-style: none !important;
    padding: 0;
  }
  li {
    font-size: 12px;
    font-weight: 500;
  }
}

.totalIncomeLegend {
  color: #f9a826;
}

.actualIncomeLegend {
  color: #c92620;
}

.totalExpensesLegend {
  color: #2ca8ff;
}

.negativeNumber {
  color: #ff0000;
}
