.outreachContainer {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.platformRow {
  margin-bottom: 20px;
}

.platformItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.platformIcon {
  padding-right: 10px;
  width: 20px;
}

.platformCheckbox {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.platformCheckboxInput {
  margin-right: 10px;
}

.toggleButton {
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.toggleButton:hover {
  color: #0056b3;
}

.contentArea {
  margin-top: 15px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.contentLabel {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
  color: #333;
}

.textArea {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  min-height: 200px; // Set a minimum height to ensure enough space
  max-height: 500px; // Optional: Set a maximum height
  padding: 10px; // Ensure there's some padding inside
  resize: vertical; // Allow the user to resize the textarea vertically
  overflow: auto; // Ensure scrollbars appear if the content exceeds the height
  // resize: none;
}


.questionsContainer {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.headerText {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.questionRow {
  margin-bottom: 20px;
}

.questionLabel {
  font-weight: 500;
  color: #555;
}

.questionInput {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 8px 12px;
  font-size: 1rem;
  color: #333;
  min-height: 120px; 
  width: 100%; 
  overflow: hidden;
}

.customSelect {
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
}

.addQuestionContainer {
  text-align: center;
}

.addQuestionButton {
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }
}


.formGroupWithDelete {
  position: relative;
  padding-right: 50px; // Space for the delete button
}

.deleteIcon {
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: 10px;  // Adjust as needed
  right: 10px;  // Adjust as needed
  transition: color 0.3s ease;

  &:hover {
    color: darkred;
  }
}


.iconCheck {
  color: green;
  margin-left: 8px;
}

.iconCross {
  color: red;
  margin-left: 8px;
}

.boardRow {
  margin-bottom: 10px;
}

.modalBody {
  padding: 20px;
}

.table {
  margin-top: 20px;
}

.totalAmount {
  font-size: 1.2em;
  text-align: right;
  margin-top: 20px;
}


