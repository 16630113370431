.card {
  width: 650px;
  margin-bottom: 0;
  padding: 0 20px;
  .btn {
    width: 30%;
  }
  @media only screen and (max-width: 500px) {
    width: inherit;
    height: 70vh;
    overflow-y: auto; 
  }
}

.cardHeader {
  padding: 15px 0 10px !important;
  position: sticky;
  top: 0;
  z-index: 111;
  background-color: #ffffff !important;
  box-shadow: 0px 15px 10px -20px #111;
}

.content {
  margin-top: 15px;
  min-height: 522px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 0;
}

.buttonContainer {
  margin: 10px 0 0;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.textarea {
  > textarea {
    min-height: 260px;
  }
}