.inputContainer {
  min-height: 208px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  [class~="form-group"] {
    width: 100%;
    margin-bottom: 10px;
  }
  [class~="form-check"] {
    margin-top: 0.65rem;
  }
  [class~="input-group"] {
    margin-bottom: 0 !important;
  }
}

.cardBody {
  padding: 0;
}

.cardFooter {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnHaveAccountContainer {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.btnHaveAccount {
  padding: 0;
  margin: 0;
  color: #000000;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.inputCodeContainer {
  min-height: 127px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin-bottom: 15px;
  [class~="form-group"] {
    width: 100%;
    margin-bottom: 0;
  }
  [class~="input-group"] {
    margin-bottom: 0 !important;
  }
}

.text {
  color: #000000;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 8px;
  > span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.termModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}