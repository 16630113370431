.modal {
  @media only screen and (max-width: 991px) {
    [class~="modal-content"] {
      margin-bottom: 56px;
    }
  }
  [class~="modal-content"] {
    overflow: auto !important;
  }
}

.card {
  margin-bottom: 0;
  display: block;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.cardHeader {
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 111;
}

.cardBody {
  padding: 1.25rem;
}

.cardFooter {
  padding: 0;
  margin: 0;
  position: sticky;
  bottom: 0;
  z-index: 111;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 12px 0;
  margin-bottom: 0;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.buttonContainer {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.textarea {
  > textarea {
    min-height: 100px;
  }
}

.content {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.commonTitle {
  color: rgb(112, 112, 112);
  margin: 0;
  font-weight: 500;
  word-wrap: break-word;
  font-size: 0.9571em;
  padding-left: 0;
  padding-right: 8px;
}

.customCheckbox {
  text-align: left;
  border-radius: 30px;
  [class~="form-check-label"] {
    color: #2c2c2c !important;
  }
  [class~="form-check-sign"] {
    &::before {
      border-radius: 30px !important;
    }
  }
}

.uploadFile {
  border: 1px solid #cccccc;
  padding: 10px;
  color: black !important;
  border-radius: 30px;
}

.customSwitchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  > p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  [class~="bootstrap-switch"] {
    margin-bottom: 0 !important;
  }
}

.sliderRateOffered {
  margin: 6px 12px 0;
}

.termModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadTermBtn {
  color: #f96332;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
