.card {
  padding: 15px 0;
  border-radius: 0.25rem;
  padding-bottom: 0.7rem;
  max-width: 350px !important;
}

.signupTitle {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.cardBody {
  padding-bottom: 0;
}

.inputContainer {
  min-height: 147px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  [class~="form-group"] {
    width: 100%;
  }
  [class~="input-group"] {
    margin-bottom: 0 !important;
  }
}

.logoContainer {
  margin: 0 auto 20px;
  width: 150px;

}
.cardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 1px;
}

.createAccount,
.forgotPassword {
  padding: 0;
  margin: 0;
  color: #000000;
  background-color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.content {
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  min-height: 100vh; /* Full viewport height */
  padding: 20px; /* Padding to ensure some spacing around */
}