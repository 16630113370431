.container {
  margin-top: 120px;
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.formRow {
  margin-top: 20px;
}

.addButton, .saveButton {
  width: 100px;
}
