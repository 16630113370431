@import '../../../../_variables.scss';
@import '../../../Mixins/mixins';

.other {
  @media only screen and (min-width: 992px) {
    display: none;
  }
}

.navbar-collapse {
  @media only screen and (max-width: 991px) {
    &::before {
      background: linear-gradient(#f2e822 0%, #000 80%) !important;
    }
  }
}

.dropdownMenu::-webkit-scrollbar {
  display: none;
}

.dropdownMenu {
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media only screen and (max-width: 991px) {
    height: auto !important;
  }
}

.logoWord {
  font-size: 1.2em;
}

.bgBlack {
  background-color: #000000 !important;
  a {
    color: #fff !important;
  }
}

.signOutDesktop {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.activeDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}

.timesheetNotify {
  position: absolute;
  top: -6px;
  left: -3px;
  font-size: 0.7em;
  color: #f96332;
  font-weight: 700;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberOfPendingTimesheets {
  height: 15px;
}

.navbarMenu {
  position: relative;
}

.navbarMenuDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 9px;
  width: 5px;
  height: 5px;
}

.offersDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}


.navbar{
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  min-height: 53px;
  // margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

  a{
      vertical-align: middle;

      &:not(.btn):not(.dropdown-item){
          color: #888;
      }
  }

  p{
      display: inline-block;
      margin: 0;
      line-height: 21px;
      font-weight: inherit;
      font-size: inherit;
  }

  .navbar-nav{
    align-items: center;
    font-size: .7142em;
    line-height: 1.625rem;
    // padding: .5rem .7rem;
    text-transform: uppercase;
      &.navbar-logo{
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 49px;
          top: -4px;
      }

      .nav-link.btn{
          padding: $padding-btn-vertical $padding-btn-horizontal;
          font-size: 11px;

          &.btn-lg{
              padding: $padding-large-vertical $padding-large-horizontal;
          }

          &.btn-sm{
              padding: $padding-small-vertical $padding-small-horizontal;
          }
      }
      .now-ui-icons.icon-badge + p {
        margin-left: 3px;
      }
    
      .now-ui-icons {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
      }
    
      .now-ui-icons.icon-badge {
        top: 4px;
        font-size: 16px;
      }
    

      .navbar-nav .now-ui-icons.icon-badge + p {
        margin-left: 3px;
      }

      .nav-link:not(.btn){
          text-transform: uppercase;
          font-size: $font-size-mini;
          padding: $padding-base-vertical $padding-base-horizontal;
          line-height: $line-height-nav-link;

          i.fab + p,
          i.now-ui-icons + p{
              margin-left: 3px;
          }
          i.icon-badge + p {
            margin-left: 3px;
          }

          i.fab,
          i.now-ui-icons{
              font-size: 18px;
              position: relative;
              top: 3px;
              text-align: center;
              width: 21px;
          }

          i.now-ui-icons{
              top: 4px;
              font-size: 16px;
          }

          &.profile-photo{
              .profile-photo-small{
                  width: 27px;
                  height: 27px;
              }
          }

          &.disabled{
              opacity: .5;
              color: $white-color;
          }
      }

      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active{
          background-color: $opacity-2;
          border-radius: $border-radius-small;
      }
  }

  .logo-container{
      width: 27px;
      height: 27px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 50%;
      border: 1px solid transparent;
  }

  .navbar-brand{
      text-transform: uppercase;
      font-size: $font-size-small;
      padding-top: $padding-base-vertical;
      padding-bottom: $padding-base-vertical;
      line-height: $line-height-nav-link;
  }

  .navbar-toggler{
      background-color: initial;
      border: 1px solid transparent;
      border-radius: .25rem;
      font-size: 1.25rem;
      line-height: 1;
      padding: .25rem .75rem;

      &.navbar-toggler-left{
          position: relative;
          left: 0;
          padding-left: 0;
      }

      .navbar-toggler-bar.middle-bar{
        width: 17px;
        transition: width .2s linear;
      }

      &:hover{
          & .navbar-toggler-bar.middle-bar{
              width: 22px;
          }
      }
  }

  .button-dropdown{
      .navbar-toggler-bar:nth-child(2){
          width: 17px;
      }
  }

  &.navbar-transparent{
      background-color: $transparent-bg !important;
      box-shadow: none;
      color: $white-color;
      padding-top: 20px !important;
  }

  &.bg-white:not(.navbar-transparent){
      a:not(.dropdown-item):not(.btn){
          color: $default-color;

          &.disabled{
              opacity: .5;
              color: $default-color;
          }
      }

      .button-bar{
          background: $default-color;
      }

      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active{
          background-color: $opacity-gray-3;
      }

      .logo-container{
          border: 1px solid $default-color;
      }
  }
}


.bg-default{
  background-color: $default-color !important;
}

.bg-primary{
  background-color: $primary-color !important;
}

.bg-info{
  background-color: $info-color !important;
}

.bg-success{
  background-color: $success-color !important;
}

.bg-danger{
  background-color: $danger-color !important;
}

.bg-warning{
  background-color: $warning-color !important;
}

.bg-white{
  background-color: $white-color !important;
}

.navbar{
  p{
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute{
	  position: absolute;
	  width: 100%;
	  padding-top: 10px;
	  z-index: 1029;
  }

  .navbar-toggler{
    vertical-align: middle;

    &.navbar-toggler-left{
      top:0;
    }

    &.navbar-toggler-right{
      padding-right: 0;
      top: 8px;
    }
  }
}

.navbar{
  p{
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute{
	  position: absolute;
	  width: 100%;
	  padding-top: 10px;
	  z-index: 1029;
  }

  .navbar-toggler{
    vertical-align: middle;

    &.navbar-toggler-left{
      top:0;
    }

    &.navbar-toggler-right{
      padding-right: 0;
      top: 8px;
    }
  }
}


@media screen and (max-width: 991px){
  @include navbar-collapse();

  .profile-photo .profile-photo-small{
      margin-left: -2px;
  }

  [class*="navbar-toggleable-"] .navbar-collapse{
      width: 300px;
  }

  .button-dropdown{
      display: none;
  }

  .section-nucleo-icons .container .row > [class*="col-"]:first-child{
      text-align: center;
  }

  .footer{
      .copyright{
          text-align: right;
      }
  }

  .section-nucleo-icons .icons-container{
      margin-top: 65px;
  }

  .section-images{
      height: 500px;
      max-height: 500px;

      .hero-images-container{
          max-width: 500px;
      }

      .hero-images-container-1{
          right: 10%;
          top: 68%;
          max-width: 269px;
      }

      .hero-images-container-2{
          right: 5%;
          max-width: 135px;
          top: 93%;
      }
  }

  .nav-open{
      .navbar-collapse{
          @include transform-translate-x(0px);
      }

      .wrapper{
          @include transform-translate-x(-150px);
      }

      .navbar-translate{
          @include transform-translate-x(-300px);
      }

      .menu-on-left{
          .navbar-collapse{
              @include transform-translate-x(0px);
          }

          .navbar-translate{
              @include transform-translate-x(300px);
          }

          .wrapper{
              @include transform-translate-x(150px);
          }

          #bodyClick{
              right: auto;
              left: 300px;
          }
      }
  }
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
      // Ensure the background gradient is applied
      &::before {
          background: linear-gradient(#f2e822 0%, #000 80%) !important;
      }
      text-align: left; // Align text to the left
      padding-left: 15px; // Add some padding to push content from the edge
      background-color: #999;
      border-top: none;
      display: block !important;
      height: 100% !important;
      max-height: none !important;
      overflow-y: visible;
      position: fixed;
      right: 0;
      text-align: left;
      top: 0;
      -webkit-transform: translate3d(300px, 0, 0);
      transform: translate3d(300px, 0, 0);
      transition: all .5s cubic-bezier(.685,.0473,.346,1);
      visibility: visible;
      width: 300px;
      z-index: 1032;
    
}
  
  .nav-item {
    width: 100%;
    text-align: left;
}
  .navbar-nav {
    align-items: flex-start!important;
    font-size: .7142em;
    line-height: 1.625rem;
    padding: .5rem .7rem;
    padding-top: 0.5rem;
    padding-right: 0.7rem;
    padding-bottom: 0.5rem;
    padding-left: 0.7rem;
    text-transform: uppercase;
  }
  .navbar-nav .nav-link {
      padding-left: 0; // Adjust padding to ensure alignment
      padding-right: 0; // Adjust padding to ensure alignment
  }

  // Ensure Dropdown items are also aligned properly
  .dropdown {
      text-align: left; // Align dropdown text to the left
      right: auto; // Reset any right alignment if present
      left: 15px; // Adjust this based on your layout requirements
  }
}