.card {
  width: 450px;
  margin-bottom: 0;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 0 40px;
}

.disclaimerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  margin-bottom: 1rem;
}

.disclaimerItem {
  width: 50%;
  padding: 10px 20px;
  border-bottom: 1px solid #f96332;
  transition: background-color 0.2s ease-in;
  &:hover {
    cursor: pointer;
    background-color: #e3e3e3;
    transition: background-color 0.2s ease-in;
  }
}

.activeItem {
  background-color: #ffffff;
  color: rgb(112, 112, 112);
  font-weight: 600;
  border-top: 1px solid #f96332;
  border-left: 1px solid #f96332;
  border-right: 1px solid #f96332;
  border-bottom: none;
}

.linkedinSubTitle {
  font-size: 14px;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
  margin-top: 20px;
  margin-bottom: 10px;
}

.subTitle {
  font-size: 12px;
  // font-weight: bold;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 25px;
}

.linkedinContent {
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cvContent {
  min-height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.commonTitle {
  color: rgb(112, 112, 112);
  margin: 0;
  // width: 130px;
  font-weight: 500;
  word-wrap: break-word;
}

.uploadFile {
  border: 1px solid #cccccc;
  padding: 10px;
  color: black !important;
  margin: 0 15px;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
