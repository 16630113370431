.main,
.summary,
.responsibility {
  p {
    font-weight: 400;
  }
}

.main {
  li {
    font-weight: 400;
    line-height: 1.61em;
    font-size: 1.2em;
  }
}

.editBtnDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 15px;
  background-color: #000000;
  color: #ffffff;
}

.editBtn {
  top: 15px;
  z-index: 1;
  background-color: #000000;
  color: #ffffff;
}

.navExItems {
  @media only screen and (max-width: 575px) {
    flex-wrap: nowrap !important;
  }
}

.navExItem {
  @media only screen and (max-width: 575px) {
    a {
      padding: 10px 10px !important;
      min-width: 70px !important;
    }
  }
}
