.wrapper {
  width: 100vw;
  background-color: #f2e822;
  position: fixed;
  bottom: 0;
  z-index: 1051;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  @media only screen and (min-width: 576px) {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f2e822;
} 

.button {
  flex: 1;
  color: #000000;
  background-color: transparent;
  font-size: 12px;
  border: none;
  border-radius: 0;
  margin: 5px 0;
  &:last-child {
    border-right: none;
  }
  &:hover {
    background-color: #f2e822;
    box-shadow: 5px 10px;
  }
  &:active {
    background-color: #f2e822;
    box-shadow: 5px 10px;
  }
}

.footerIcon {
  font-size: 1.8em;
}

.timesheetNotify {    
  position: absolute;
  top: -7px;
  left: -4px;
  font-size: 0.7em;
  color: #f96332;
  font-weight: 700;
  background-color: #fff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberOfPendingTimesheets {
  height: 20px;
}

.messageIcon {
  position: relative;
}

.messageDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: -2px;
  left: -2px;
  width: 5px;
  height: 5px;
}

