.inputContainer {
  min-height: 147px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  [class~="form-group"] {
    width: 100%;
    margin-bottom: 10px;
  }
  [class~="input-group"] {
    margin-bottom: 0 !important;
  }
}

.cardFooter {
  margin: 0 !important;
  padding: 0 !important;
}

.btnContainer {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 1px;
}

.createAccount,
.forgotPassword {
  padding: 0;
  margin: 0;
  color: #000000;
  background-color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}