.other {
  @media only screen and (min-width: 992px) {
    display: none;
  }
}

.navbarCollapse {
  @media only screen and (max-width: 991px) {
    &::before {
      background: linear-gradient(#f2e822 0%, #000 80%) !important;
    }
  }
}

.dropdownMenu::-webkit-scrollbar {
  display: none;
}

.dropdownMenu {
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media only screen and (max-width: 991px) {
    height: auto !important;
  }
}

.logoWord {
  font-size: 1.2em;
}

.bgBlack {
  background-color: #000000 !important;
  a {
    color: #fff !important;
  }
}

.signOutDesktop {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.activeDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}

.timesheetNotify {
  position: absolute;
  top: -6px;
  left: -3px;
  font-size: 0.7em;
  color: #f96332;
  font-weight: 700;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberOfPendingTimesheets {
  height: 15px;
}

.navbarMenu {
  position: relative;
}

.navbarMenuDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 9px;
  width: 5px;
  height: 5px;
}

.offersDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}
