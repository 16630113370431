.card {
  padding: 15px 0;
  border-radius: 0.25rem;
  padding-bottom: 0 !important;
  max-width: 350px !important;
}

.title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.inputContainer {
  min-height: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  [class~="form-group"] {
    width: 100%;
  }
  [class~="input-group"] {
    margin-bottom: 0 !important;
  }
}

.inputCodeContainer {
  min-height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  [class~="form-group"] {
    width: 100%;
  }
  [class~="input-group"] {
    margin-bottom: 0 !important;
  }
}

.content {
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  min-height: 100vh; /* Full viewport height */
  padding: 20px; /* Padding to ensure some spacing around */
}

.text {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}