.pageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: none;
  overflow-y: auto;
}

.content {
  z-index: 1000;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 767px) {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    z-index: 1030;
  }
}

.cardWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.card {
  background-image: url("../../../../../assets/img/bg21.jpg");
  @media only screen and (max-width: 767px) {
    margin: 15px auto 0 !important;
    box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 20%);
  }
  margin: 30px 0;
}

.cardContainerWrapper {
  position: relative;
}

.declineWord {
  padding: 0 5px;
  position: absolute;
  top: 42px;
  right: 35px;
  color: #ff3636;
  z-index: 1056;
  border: 2px solid;
  border-radius: 0.475em;
  font-size: 1.5em;
  font-weight: 400;
  opacity: 0;
  transform: rotate(35deg) scale(0.3);
}

.progressWord {
  padding: 0 5px;
  position: absolute;
  top: 42px;
  left: 35px;
  color: #18ce0f;
  z-index: 1056;
  border: 2px solid;
  border-radius: 0.475em;
  font-size: 1.5em;
  font-weight: 400;
  opacity: 0;
  transform: rotate(-35deg) scale(0.3);
}

.tinderDeclineCard {
  & .declineWord {
    opacity: 1;
    transform: rotate(35deg) scale(1);
  }
  z-index: 1052 !important;
}

.tinderAcceptCard {
  & .progressWord {
    opacity: 1;
    transform: rotate(-35deg) scale(1);
  }
  z-index: 1052 !important;
}

.cardContainer {
  position: relative;
}

.btnBack {
  position: absolute;
  color: #2c2c2c !important;
  margin: 8px 0;
  font-size: 1.4em;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.cardTitle {
  font-size: 2.1em !important;
}

.carousel {
  width: 100%;
  background-color: #ffffff;
  [class~="carousel-control"] {
    color: #000000;
  }
  [class~="carousel-indicators"] {
    display: none;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  @media only screen and (max-width: 767px) {
    justify-content: flex-end;
    min-height: 76px !important;
  }
}

.btnWrapper {
  display: none;
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.btnProgressMobile {
  @media only screen and (max-width: 767px) {
    z-index: 1050;
    position: fixed;
    bottom: 66px;
    left: 100px;
    margin: 0;
    border-radius: 50%;
    padding: 22px 4px !important;
  }
}

.btnDeclineMobile {
  @media only screen and (max-width: 767px) {
    z-index: 1050;
    position: fixed;
    bottom: 66px;
    left: 20px;
    margin: 0;
    border-radius: 50%;
    padding: 22px 8px !important;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  padding: 0;
  margin: 0;
  max-width: none;
  [class~="description"] {
    color: #000;
  }
}

.descriptionContainer {
  p {
    color: #000000 !important;
  }
  white-space: pre-wrap;
  @media only screen and (max-width: 575px) {
    font-size: 1em !important;
  }
}

.cardNoMoreItem {
  background-image: url("../../../../../assets/img/bg21.jpg");
  box-shadow: none;
  [class~="card-body"] {
    min-height: 370px;
    max-width: 570px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 575px) {
    [class~="card-title"] {
      font-size: 2em;
    }
  }

  @media only screen and (max-width: 767px) {
    height: 100vh;
    margin-bottom: 0;
  }
  position: relative;
}

.btnBackNoMoreItem {
  color: #fff !important;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1000;
  font-size: 1.4em;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.displayNone {
  // display: none !important;
  visibility: hidden;
}

.signOutBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
  margin-top: 15px;
}

.swipe {
  padding-bottom: 120px;
  background-color: #ffffff;
  position: absolute;
  z-index: 0;
  width: 100%;
  max-height: fit-content;
  min-height: 100%;
}

.containerCarousel {
  position: absolute;
  z-index: 0;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.containerTinderCard {
  display: none;
  @media only screen and (max-width: 767px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
  @media only screen and (min-width: 576px) {
    max-width: unset;
  }
  position: relative;
}

.btnBackMobile {
  z-index: 1050;
  position: fixed;
  bottom: 66px;
  right: 20px;
  margin: 0;
  border-radius: 50%;
  padding: 22px 15px !important;
  box-shadow: 0px 20px 88px 0px rgb(0 0 0);
}

.linkAssist {
  color: #fff !important;
  border-bottom: 2px solid #fff !important;
}
