.wrapper {
  position: relative;
  @media only screen and (max-width: 650px) {
    max-height: unset;
    min-height: unset;
  }
}

.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 651px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.content {
  margin-top: 100px !important;
  @media only screen and (max-width: 767px) {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width: 650px) {
    margin: 0 !important;
  }
}

.container {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 650px) {
    padding: 0 !important;
    margin: 0 !important;
    max-width: unset;
    min-height: calc(100vh - 139px);
  }
}

.card {
  max-width: 700px;
  color: #000000;
  padding: 30px 25px;
  margin-bottom: 0;
  overflow: auto;
  @media only screen and (max-width: 650px) {
    min-height: calc(100vh - 139px);
  }
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media only screen and (max-width: 538px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}
.headerItem {
  width: 175px;
  padding: 10px 20px;
  border-radius: 0.1875rem;
  @media only screen and (max-width: 767px) {
    width: 140px;
    padding: 10px 15px;
  }
  @media only screen and (max-width: 538px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
}
.backgroundPending {
  color: #ffffff;
  background-color: #e86c42;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
  }
}
.backgroundApproved {
  color: #ffffff;
  background-color: #42bee8;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
  }
}
.backgroundPaid {
  color: #ffffff;
  background-color: #bf42e8;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
  }
}
.headerItemTitle {
  display: flex;
  flex-direction: column;
  > p {
    margin: 0;
    font-weight: 500;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 538px) {
    flex-direction: row;
    gap: 5px;
  }
}
.headerItemPrice {
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}
.verticalDiviver {
  height: 70px;
  border-left: 1px solid #000000;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0;
  justify-content: center;
  min-height: 343px;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 5px;
    align-items: unset;
  }
}
.inputTitle {
  margin: 0;
  width: 140px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  word-wrap: break-word;
  @media only screen and (max-width: 767px) {
    margin-left: 7px;
  }
}
.input {
  flex: 1;
  margin: 0;
}

.cardFooter {
  margin-top: 0 !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  > button {
    margin: 0;
    flex: 1;
  }
  @media only screen and (max-width: 767px) {
    gap: 20px;
  }
}

.buttonDelete {
  color: #ffffff;
  background-color: #e86c42;
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    background-color: #e86c42;
    transition: all 0.3s;
  }
}

.buttonSave {
  color: #ffffff;
  background-color: #42bee8;
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    background-color: #42bee8;
    transition: all 0.3s;
  }
}

.buttonSubmit {
  color: #000000;
  background-color: #f2e822;
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
    background-color: #f2e822;
    color: #000000;
  }
}

.footer {
  @media only screen and (max-width: 650px) {
    display: none;
  }
}

.disabledTime {
  background-color: #E3E3E3;
  border-radius: 30px;
}