.container {
  padding-top: 90px !important;
  background-color: #000000;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.editBtnDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 15px;
  background-color: #000000;
  color: #ffffff;
}

.editBtn {
  top: 80px;
  z-index: 1;
  background-color: #ffffff;
  color: #000000;
}

.backButton {
  position: absolute;
  top: 10x;
  left: 10px;
}

.infoRow {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  .infoGroup {
    margin-bottom: 8px;
    p {
      line-height: 1.2em;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.6);
    }
    h2 {
      font-size: 1.2em;
      margin-bottom: 0;
      @media (min-width: 576px) {
        font-size: 1.4em;
        margin-bottom: 15px;
      }
    }
  }
}

.pageHeader {
  min-height: unset;
  max-height: unset;
  background-color: #000000;
}

.description {
  font-weight: 400;
  text-align: center;
  margin-bottom: 1rem !important;
}

.testimonialNumbers{
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}