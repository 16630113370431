@import './_variables.scss';
@import './components/Mixins/mixins.scss';

// Plugins CSS
@import './assets/scss/plugins/plugin-bootstrap-switch';
@import './assets/scss/plugins/plugin-nouislider';
@import './assets/scss/plugins/plugin-bootstrap-select';
@import './assets/scss/plugins/plugin-tagsinput';
@import './assets/scss/plugins/plugin-fileupload';
@import './assets/scss/plugins/plugin-datetimepicker';

// dashboard plugins
// Plugins CSS
@import "./assets/scss/plugins/plugin-animate-bootstrap-notify";
@import "./assets/scss/plugins/plugin-perfect-scrollbar";
@import "./assets/scss/plugins/plugin-card-wizard";
@import "./assets/scss/plugins/plugin-jasny-fileupload";
@import "./assets/scss/plugins/plugin-datatables.net";
@import "./assets/scss/plugins/plugin-jquery.jvectormap";
@import "./assets/scss/plugins/plugin-fullcalendar";

////// Core CSS 
//// FONTS
@import './assets/fonts/fonts/hippopotamus-apocalypse';
@import './assets/fonts/fonts/nucleo-outline';

@import './assets/scss/core/alerts';
@import './assets/scss/core/checkboxes-radio';
@import './assets/scss/core/badges';
@import './assets/scss/core/pagination';
@import './assets/scss/core/typography';
@import './assets/scss/core/misc';
@import './assets/scss/core/pills';

@import './assets/scss/core/buttons';
@import './assets/scss/core/inputs';

@import './assets/scss/sections/sections';

@import './assets/scss/sections/sections-extend';

.node rect {
    stroke: #333;
    fill: #fff;
    stroke-width: 1.5px;
  }
  
  .edgePath path.path {
    stroke: #333;
    fill: none;
    stroke-width: 1.5px;
  }
  
  .arrowhead {
   stroke: blue;
   fill: blue;
   stroke-width: 1.5px;
  }

  
// sign up page - needs to be moved to the sign up page 
.signup-page .section {
    padding-top: 180px;
    padding-bottom: 0; }
  
  .signup-page .input-group, .signup-page .form-group {
    margin-bottom: 20px; }
  
  .signup-page .card-signup {
    max-width: 100%;
    margin-bottom: 130px;
    padding: 15px 0px; }
    .signup-page .card-signup:not([data-background-color]) .card-title {
      color: initial; }
    .signup-page .card-signup .checkbox label {
      margin-left: 14px;
      padding-left: 39px;
      color: initial; }
  
  .signup-page .info-horizontal {
    padding: 0px 0px 20px; }
  
  .signup-page .social .btn {
    margin: 5px; }
  
  .signup-page .social h4 {
    margin-top: 20px; }
// cards
.card{
    border: 0;
    border-radius: $border-radius-small;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  
    .card-header{
      background-color: $transparent-bg;
      border-bottom: 0;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
    }
  
    .card-footer{
      padding: 0;
      margin-top: 15px;
      background-color: $transparent-bg;
      border:0;
    }
  
    &[data-background-color="orange"]{
        background-color: $primary-color;
    }
  
    &[data-background-color="red"]{
        background-color: $danger-color;
    }
  
    &[data-background-color="yellow"]{
        background-color: $warning-color;
    }
  
    &[data-background-color="blue"]{
        background-color: $info-color;
    }
  
    &[data-background-color="green"]{
        background-color: darken($success-color, 5%);
    }
  }
@import './assets/scss/cards/card-extend';
@import './assets/scss/cards/card-background';
@import './assets/scss/cards/card-blog';
@import './assets/scss/cards/card-contact';
@import './assets/scss/cards/card-fashion';
@import './assets/scss/cards/card-form-horizontal';
@import './assets/scss/cards/card-no-shadow';
@import './assets/scss/cards/card-plain';
@import './assets/scss/cards/card-pricing';
@import './assets/scss/cards/card-profile';
@import './assets/scss/cards/card-product';
@import './assets/scss/cards/card-raised';
@import './assets/scss/cards/card-signup';
@import './assets/scss/cards/card-testimonials';




// react
@import './assets/scss/react/react-differences';


// @import './assets/scss/now-ui-kit/tabs';
// @import './assets/scss/now-ui-kit/navbar';
// @import './assets/scss/now-ui-kit/navbar-extend';
// @import './assets/scss/now-ui-kit/dropdown';
// @import './assets/scss/now-ui-kit/dropdown-extend';
// @import './assets/scss/now-ui-kit/images';
// @import './assets/scss/now-ui-kit/popups';
// @import './assets/scss/now-ui-kit/tables';
// @import './assets/scss/now-ui-kit/info-areas';
// @import './assets/scss/now-ui-kit/media';
// @import './assets/scss/now-ui-kit/modals';
// @import './assets/scss/now-ui-kit/modals-extend';
@import "./assets/scss/now-ui-kit/sidebar-and-main-panel";
// @import './assets/scss/now-ui-kit/carousel';
// @import './assets/scss/now-ui-kit/footers';

@import './assets/scss/now-ui-kit/responsive'; 

//sidebar sliding back and forth
@media (min-width: 991px) {
  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .navbar.fixed-top2 {
      width: calc(100% - 80px);
    }

    .navbar-minimize {
      opacity: 0;
    }

    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      z-index: 1030;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
        .nav li span {
            @include transform-translate-x(-25px);
            display: none;
          }
        .nav li {
            margin: 0 1.5em;  /* Centers horizontally */
        }
        .nav h6 {
        opacity: 0;
        }
      }
    }

    .sidebar:hover {
      width: 260px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0);
        }
      }

      .navbar-minimize {
        opacity: 1;
      }
      .sidebar-wrapper {
        width: 260px;

        > .nav li > a p,
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span {
          @include transform-translate-x(0px);
          display: block!important;
        }
        .nav li span {
            @include transform-translate-x(+5px);
            display: inline-block;
            
          }
        .nav h6 {
        opacity: 1;
        }
      }
    }
  }
}



  
// FONTS
@font-face {
  font-family: 'Hippopotamus Apocalypse';
  src: url('./assets/fonts/HippopotamusApocalypse.eot');
  src: url('./assets/fonts/HippopotamusApocalypse.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/HippopotamusApocalypse.woff2') format('woff2'),
  url('./assets/fonts/HippopotamusApocalypse.woff') format('woff'),
  url('./assets/fonts/HippopotamusApocalypse.ttf') format('truetype'),
  url('./assets/fonts/HippopotamusApocalypse.svg#HippopotamusApocalypse') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// // Plugins CSS
@import './assets/scss/plugins/plugin-bootstrap-switch';
@import './assets/scss/plugins/plugin-nouislider';
@import './assets/scss/plugins/plugin-bootstrap-select';
@import './assets/scss/plugins/plugin-tagsinput';
@import './assets/scss/plugins/plugin-fileupload';
@import './assets/scss/plugins/plugin-datetimepicker';




// original 
.has-danger {
  .input-group {
    .input-group-append,
    .input-group-prepend {
      > * {
        border-color: #ffcfcf !important;
      }
    }
  }
}

.content-center {
  .page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .page-header > .content {
    flex: 1;
    width: 100%;
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.pac-container {
  z-index: 1100;
}

 
//example pages

style for the landing page
.index-page,
.presentation-page{
    .page-header{
        height: 125vh;

        .container{
            > .content-center{
                top: 37%;
            }
        }
    }
}

.index-page,
.presentation-page{
    .category-absolute{
        position: absolute;
        top: 100vh;
        margin-top: -60px;
        padding: 0 15px;
        width: 100%;
        text-align: center;
        color: rgba(255,255,255,.5);
    }
}

.presentation-page{
    .section-sections{
        // padding-bottom: 0;
        // margin-bottom: -120px;

        .section-description{
            text-align: center;
            margin-bottom: 60px;
        }

        .col-md-3{
            padding: 7.5px;
        }

        img{
            width: 100%;
            border-radius: $border-radius-small;
            transition: all .2s $transition-ease;
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

            &:hover{
                @include transform-scale(1.02);
            }
        }
    }

    .section-pricing{
        .card-pricing{
            margin-top: 45px;
        }

        .card-margin{
            margin-top: 90px;
        }
    }
}

.blog-posts{
    .projects-4{
        padding-top: 0;
    }
}

.ecommerce-page{
    .container{
        position: relative;
        z-index: 2;
    }

    .card-header{
        padding-left: 0;
        padding-right: 0;

        &:after{
            width: 100%;
            left: 0;
        }
    }

    .slider-refine{
        margin: 15px 0;
    }

    .card-refine{
        & > .card-title{
            margin-bottom: 30px;
        }

        [role="tabpanel"]{
          max-height: 273px;
          overflow-y: scroll;
        }

        h6{
          margin-top: .5rem;
        }

        .card-body{
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 0;

            &.panel-refine{
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .price-right,
        .price-left{
            font-weight: 500;
        }

        .btn{
            margin: -5px -12px;
        }

        .checkbox input[type=checkbox]:checked + .checkbox-material .check{
            background: $primary-color;
        }
    }

    .card-product{
        .card-image{
            width: 200px;
            margin: 0 auto;
        }
    }
}

.product-page{
    background-color: #eee;

   .carousel{
        margin: -90px 0px 0;

        img {
          border-radius: $border-radius-small;
          height: auto;
        }
    }
    .section{
        padding-top: 30px;
    }
    .main-price{
      margin: 10px 0 25px;
      font-weight: $font-weight-light;
    }

    .blockquote{
        width: 90%;
        margin-top: -10px;
        margin-left: 5%;
    }

    .pick-size{
        margin-top: 15px;
        margin-bottom: 15px;
        .form-group{
            margin-top: 5px;
        }
    }

    .related-products{

        .title{
            margin-bottom: 80px;
        }
    }
    .features{
      padding-top: 30px;
    }
    .accordeon .card{
        margin-bottom: 15px;
    }
}

.landing-page{

    .page-header{
        height: 100vh;
        position: relative;

        .share{
            margin-top: 150px;
        }
        h1{
            font-weight: 600;
        }
        .title{
            color: $white-color;
        }
    }

    .section-team{
        .team .team-player img{
            max-width: 100px;
        }

        .team-player{
            margin-bottom: 15px;
        }
    }

    .section-contact-us{
        .title{
            margin-bottom: 15px;
        }

        .description{
            margin-bottom: 30px;
        }

        .input-group,
        .send-button,
        .textarea-container{
            padding: 0 40px;
        }

        .textarea-container{
            margin: 40px 0;
        }

        a.btn{
            margin-top: 35px;
        }
    }
}

.profile-page,
.blog-post{
    .button-container{
        text-align: center;
        margin-top: -106px;
        position: relative;
        z-index: 100;
    }
}

// style for the profile page
.profile-page{
    .profile-container{
        color: #FFFFFF;
    }
    .photo-container{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
        img{
          width: 100%;
          border-radius: $border-radius-small;
          transition: all .2s $transition-ease;
          box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  
          &:hover{
              @include transform-scale(1.02);
          }
      }
    }


    .title{
        text-align: center;
        margin-top: 30px;
    }

    .category{
        text-align: center;
    }

    h5.description{
        max-width: 700px;
        margin: 20px auto 75px;
    }

    .nav-align-center{
        margin-top: 30px;
    }

    .info-horizontal{
        padding: 15px 0 0;
    }
    .content{
        max-width: 450px;
        margin: 0 auto;

        .social-description{
            display: inline-block;
            max-width: 150px;
            width: 145px;
            text-align: center;
            margin: 15px 0 0px;

            h2{
                margin-bottom: 5px;
            }
        }
    }

    .collections{
        img{
            margin-bottom: 30px;
        }
    }

    .gallery{
        margin-top: 45px;
        padding-bottom: 50px;
    }
}

.section-full-page{

    &:after,
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before{
        background-color: rgba(0,0,0,.5);
    }

    &[filter-color="purple"],
    &[filter-color="primary"]{
        &:after{
            @include linear-gradient(rgba($light-gray,.26), rgba($primary-color,.95));

        }

    }

    &[data-image]:after{
        opacity: .5;
    }

    > .content,
    > .footer{
        position: relative;
        z-index: 4;
    }

    > .content{
        min-height: calc(100vh - 80px);
    }

    .full-page-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

    }

    .footer nav > ul a:not(.btn),
    .footer,
    .footer .copyright a{
        color: $white-color;
    }

}

.signup-page{
    .section{
        padding-top: 180px;
        padding-bottom: 0;
    }

    .input-group, .form-group{
        margin-bottom: 20px;
    }

    .card-signup{
        max-width: 100%;
        margin-bottom: 130px;
        padding: 15px 0px;

        &:not([data-background-color]) .card-title{
            color: initial;
        }

        .checkbox label{
            margin-left: 14px;
            padding-left: 39px;
            color: initial;
        }
    }

    .info-horizontal{
        padding: 0px 0px 20px;
    }
    .social{
        .btn{
            margin: 5px;
        }
        h4{
            margin-top: 20px;
        }
    }
}

.login-page{

    .page-header{
      display: block;
      align-items: unset;
    }

    .card-login{
        border-radius: $border-radius-large;
        padding-bottom: $padding-base-horizontal;
        max-width: 320px;

        .btn-wd{
            min-width: 180px;
        }

        .logo-container{
            width: 150px;
            margin: 0 auto;
            margin-bottom: 20px;
            

            img{
                width: 100%;
            }
        }

        .input-group:last-child{
            margin-bottom: 40px;
        }

        &.card-plain{
            @include input-coloured-bg($opacity-5, $white-color, $white-color, $transparent-bg, $opacity-1, $opacity-2);

            .input-group-text,
            .form-group.no-border .input-group-text,
            .input-group.no-border .input-group-text{
                color: $opacity-8;
            }
        }
    }

    .link{
        font-size: 10px;
        color: $white-color !important;
        text-decoration: none;

        &:hover,
        &:focus{
            border-color: $white-color !important;
        }
    }
}

.contact-page{
    .contact-content{
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .container{
        max-width: 970px;
    }
    .big-map{
        height: 55vh;
        width: 100%;
        display: block;
        position: relative;
    }
    .message{
        margin-top: 20px;
    }
    .info{
        padding-bottom: 10px;
        padding-top: 0px;
        .title{
            margin: 25px 0 10px;
        }
    }
}


.blog-post{
    .section-blog-info{
        padding-top: 30px;
        padding-bottom: 0px;

        .btn{
            margin-top: 0;
            margin-bottom: 0;
        }
        .blog-tags{
            padding-top: 8px;
        }
        .card-profile{
            margin-top: 0;
            text-align: left;

            .description{
                font-size: $font-size-base;
            }
            .btn{
                margin-top: 25px;
            }
        }
    }
    .section-comments{
        .title{
            margin-bottom: 30px;
        }
    }

    .media{
        p{
            color: $default-color;
        }
    }
}

.about-us{
    .page-header{
        .container{
            color: #FFFFFF;
            text-align: center;
        }
        .title{
            color: $white-color;
        }
    }
    .about-description{
        padding: 70px 0px 0px 0px;
    }
    .about-services{
        padding-top: 10px;
    }
    .about-office{
        .description{
            margin-bottom: 70px;
        }
        img{
            margin: 20px 0px;
        }
    }

    .about-contact,
    .about-office{
        padding: 80px 0px;
        .description{
            margin-bottom: 70px;
        }
    }
}

.sections-page .page-header{
  display: flex;
  align-items: center;
}



.nav-open {
.main-panel {
    right: 0;
    @include transform-translate-x(260px);
}

.sidebar {
    @include transform-translate-x(0px);
    box-shadow: $sidebar-box-shadow;
}

body {
    position: relative;
    overflow-x: hidden;
}

.menu-on-right {
    .main-panel {
    @include transform-translate-x(-260px);
    }

    .navbar-collapse,
    .sidebar {
    @include transform-translate-x(0px);
    }

    .navbar-translate {
    @include transform-translate-x(-300px);
    }

    #bodyClick {
    right: 260px;
    left: auto;
    }
}
}

.menu-on-right {
.sidebar {
    left: auto;
    right: 0;
    @include transform-translate-x(260px);
}
}
