
.button {
  margin-right: 10px !important;
}


.enabledCard {
  background-color: #eee1b6; /* Light green for enabled state */
}


.integrationContainer {
  padding: 40px 0;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  margin-bottom: 10px!important;
  img{
    margin-left: 10px;
  }
  h5{
    margin-left: 10px;  
    font-weight: 600;
  }
}


.headerSection {
    background-color: #fff;
    padding: 20px;
    // margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  

    .title {
      color: #000;
      font-size: 1.4rem;
      font-weight: 600;
      margin: 0;
      // text-align: center;
    }

  p {
    // max-width: 800px;
    margin: 0 auto;
    font-weight: 400;
  }
}

.container {
  display: flex;
  flex-direction: column;
  // padding-bottom: 15px;
  padding-top: 110px;
}


.integrationLogo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.card {
  border-radius: 8px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  // padding: 20px;
}

.card-body {
  padding: 20px;
}

.btn-toggle {
  min-width: 100px;
  border-radius: 20px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  &:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }
}

.mb-3 {
  margin-bottom: 14px !important;
}

.shadow-sm {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.integrationInfo {
  font-size: 1rem;
  color: #555;
  font-weight: 400;
  margin-bottom: 0; /* Remove the bottom margin for better alignment */
  display: flex;
  align-items: center; /* Vertically center within its container */
  // justify-content: flex-end; /* Align to the right */
  // text-align: right;
  // margin-left: 30px;
  padding-bottom: 40px;

  
}


@media (max-width: 768px) {

  .container {
    display: flex;
    flex-direction: column;
    // padding-bottom: 15px;
    padding-top: 80px;
  }


  .integrationLogo {
    width: 40px;
    height: 40px;
  }

  .integrationInfo {
  display: none;
  }

  .btn-toggle {
    width: 80px;
    padding: 5px;
    font-size: 0.9rem;
  }

  .headerSection h2 {
    font-size: 1.8rem;
    background-color:#fff;
  }

  .headerSection p {
    font-size: 1rem;
  }
}
