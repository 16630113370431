.containerRight {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  padding: 10px 15px;
  background-color: #000000;
  @media only screen and (max-width: 575px) {
    min-height: calc(100vh - 186.5px);
    padding: 0;
  }
}

.carouselItemContainer {
  display: flex;
  background-color: #000000;
}

.imageContainer {
  position: relative;
  width: 50%;
}

.fullImage {
  width: 100%;
  height: 100%;
}

.buttonOverlay {
  position: absolute;
  top: 50%;               /* Move it vertically to the center */
  left: 50%;              /* Move it horizontally to the center */
  transform: translate(-50%, -0%);  /* Adjust for its own size */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center;    /* Center children horizontally */
  width: 80%;  
         /* For instance, 80% of the parent, adjust as needed */
}

.jobDetailsContainer {
  width: 50%;
  padding: 20px; // adjust as needed
}

.titleHeaderRight {
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
  }
}

.headerRight {
  display: flex;
  
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
  }
}
.avatar {
  width: 200px;
  height: auto;
  object-fit: cover;
  @media only screen and (max-width: 575px) {
    display: none;
  }
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  @media only screen and (max-width: 575px) {
    display: none;
  }
}
.btnMobile {
  @media only screen and (max-width: 575px) {
    padding: 10px 20px;
    font-size: 0.8571em;
  }
}

.descriptionContainer {
  white-space: pre-wrap;
  margin-bottom: 50px;
  @media only screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
}
.descriptionHeader {
  display: flex;
  flex-direction: row;
  color: white;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 11px;
}
.descriptionTitle {
  margin: 0;
  font-size: 16px;
  color: white;
  font-weight: 600;
}
.descriptionSubtitle {
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: 500;
}
.descriptionContent {
  margin: 0;
  font-size: 12px;
  color: white;
  font-weight: 500px;
}

.title {
  margin-bottom: 0px;
  font-size: 12px;
  color: white;
  font-weight: 600;
}
.responsibilityContainer {
  white-space: pre-wrap;
  font-size: 12px;
  color: white;
}

.mobileButtons {
  z-index: 1501;
  position: fixed;
  bottom: 66px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mobileBtn {
  margin: 0;
  border-radius: 50%;
  padding: 0 !important;
  height: 66px;
  width: 66px;
  z-index: 1501;
}

.carousel {
  width: 100%;
  background-color: #000000!important;
  [class~="carousel-control"] {
    color: #000000;
  }
  [class~="carousel-indicators"] {
    display: none;
  }
  [class~="carousel-inner"] {
    box-shadow: none;
  }
}

.containerCarousel {
  @media only screen and (max-width: 575px) {
    display: none;
  }
}

.containerTinderCard {
  overflow: auto;
  display: none;
  @media only screen and (max-width: 575px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
}

.swipe {
  background-color: #000000;
  position: absolute;
  width: 100%;
  max-height: fit-content;
  min-height: 100%;
  padding: 20px 30px 0 30px;
  overflow: auto;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.noOfferContainer {
  color: white;
  padding: 20px 30px;
}

.cardContainerWrapper {
  background-color: #000000;
  position: relative;
}

.declineWord {
  padding: 0 5px;
  position: absolute;
  top: 28px;
  right: 8px;
  color: #FF3636;
  background-color: #fff;
  z-index: 1503;
  border: 2px solid;
  border-radius: 0.475em;
  font-size: 1.5em;
  font-weight: 400;
  opacity: 0;
  transform: rotate(35deg) scale(0.3);
}

.progressWord {
  padding: 0 5px;
  position: absolute;
  top: 28px;
  left: 8px;
  color: #18ce0f;
  background-color: #fff;
  z-index: 1503;
  border: 2px solid;
  border-radius: 0.475em;
  font-size: 1.5em;
  font-weight: 400;
  opacity: 0;
  transform: rotate(-35deg) scale(0.3);
}

.tinderDeclineCard {
  & .declineWord {
    opacity: 1;
    transform: rotate(35deg) scale(1);
  }
  z-index: 1502;
}

.tinderAcceptCard {
  & .progressWord {
    opacity: 1;
    transform: rotate(-35deg) scale(1);
  }
  z-index: 1502;
}