.modal {
  @media only screen and (max-width: 767px) {
    margin: 0 !important;
    max-width: unset;
    [class~="modal-content"] {
      min-width: unset !important;
      height: 100%;
      max-height: unset;
      border-radius: 0 !important;
    }
  }
  @media only screen and (min-width: 768px) {
    [class~="modal-content"] {
      max-width: 500px;
    }
  }
  @media only screen and (max-width: 575px) {
    height: calc(100% - 3.5rem) !important;
    min-height: unset !important;
  }
}

.card {
  margin-bottom: 0;
  display: block;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.cardBody {
  padding: 0 1.25rem;
  overflow: auto;
  @media only screen and (max-width: 767px) {
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.content {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.customSwitchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 6px;
  > p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  [class~="bootstrap-switch"] {
    margin-bottom: 0 !important;
  }
}
