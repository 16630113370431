.header {
  height: 81px;
  background-color: #000000;
}
.section {
  position: relative;
  min-height: calc(100vh - 161px);
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0 75px;
  @media only screen and (max-width: 575px) {
    padding: 0;
  }
}

.contentLeft {
  width: 455px;
  border-right: 1px solid #e3e3e3;
  padding-right: 30px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
.activeClientContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.activeClientTitle {
  font-size: 18px;
  font-weight: 600;
}
.activeClientItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  &:hover{
    cursor: pointer;
    color: #f96332;
  }
}
.activeClientName {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.activeClientDuration {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}
.documentHistory {
  font-size: 18px;
  font-weight: 600;
}

.contentRight {
  width: calc(100% - 275px);
  padding: 0 50px;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  [class~="nav-pills-primary"] {
    gap: 20px;
    margin-bottom: 30px;
    justify-content: center;
    @media only screen and (max-width: 575px) {
      min-height: calc(100vh - 140px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }
  }
  [class~="nav-link"] {
    margin-right: 0 !important;
    @media only screen and (max-width: 575px) {
      &[class~="active"] {
        background-color: rgba(222, 222, 222, 0.3) !important;
        box-shadow: unset !important;
        color: #444444 !important;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: #f96332 !important;
      color: #ffffff !important;
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 0;
  }
}
.offerMobile {
  @media only screen and (max-width: 575px) {
    display: none;
  }
}
.backContainer {
  background-color: #ffffff;
  padding: 10px 30px;
  > i {
    font-size: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 576px) {
    display: none;
  }
}

.actionsTitle {
  font-size: 16px;
  font-weight: 500;
}

.interviewLink {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.actionsContent {
  font-size: 14.5px;
}

.downloadBtn {
  color: #f96332;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.navLinkItem {
  position: relative;
}

.offersDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  right: 5px;
  width: 4px;
  height: 4px;
}
