.modal {
  @media only screen and (max-width: 991px) {
    [class~="modal-content"] {
      margin-bottom: 56px;
    }
  }
  @media only screen and (min-width: 991px) {
    [class~="modal-content"] {
      width: calc(100vw - 400px);
    }
  }
}

.card {
  margin-bottom: 0;
  display: block;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.cardBody {
  padding: 0 1.25rem;
  overflow: auto;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.content {
  min-height: 571px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  [class~="input-group-text"] {
    padding: 0 18px 0 0 !important;
  }
}

.commonItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.commonSubtitle {
  margin: 0 0 0 10px;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}
.commonSubtitleFutureOpportunities {
  margin-bottom: -28px;
}
.customRadio {
  text-align: left;
  border-radius: 30px;
  display: flex;
  gap: 100px;
  [class~="form-check-sign"] {
    &::before {
      border-radius: 30px !important;
    }
  }
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.uploadCvWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;
}

.uploadTitle {
  color: rgb(112, 112, 112);
  margin: 0;
  font-weight: 500;
  word-wrap: break-word;
  font-size: 0.9571em;
  padding-left: 0;
  padding-right: 8px;
}

.uploadFile {
  border: 1px solid #cccccc;
  padding: 10px;
  color: black !important;
  border-radius: 30px;
}

.btnDeleteWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btnDelete {
  width: fit-content !important;
}

.searchIcon {
  color: #808080;
  padding-left: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
