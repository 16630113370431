.modal {
  @media only screen and (min-width: 576px) {
    max-width: 595px !important;
  }
}

.pdfWrapper {
  width: 595px;
  padding-bottom: 60px;
}

.title {
  color: #000000;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}

.subTitle {
  color: #000000;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  [class~="table-responsive"]{
    width: 94%;
  }
}

.table {
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        padding: 10px 12px !important;
      }
    }
  }
}

.contentTitle{
  color: #000000;
  font-weight: 600;
}

.btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  margin-top: -50px;
}