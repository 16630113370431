.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  padding-top: 100px;
}

.kanbanBoard {
  padding-top: 20px;
  width:110%;
  display: flex;
  overflow-x: auto;
  height: calc(100vh - 200px);  /* Fixed height for the board */
  touch-action: pan-x; /* Enable horizontal swiping */
}

.columnWrapper {
  display: block;
  flex-shrink: 0;
  align-self: flex-start;
  padding: 0 6px;
  height: 100%;
  white-space: nowrap;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-basis: 272px;
  flex-direction: column;
  flex-grow: 0;
  height: 100%;
  flex-shrink: 0;
  align-self: start;
  justify-content: space-between;
  width: 272px;
  max-height: 100%;
  padding-bottom: 8px;
  border-radius: 12px;
  background-color: var(--accent-background, var(--tr-background-list));
  box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
  color: var(--accent-text, var(--ds-text-subtle, #44546f));
  vertical-align: top;
  white-space: normal;
  scroll-margin: 8px;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  h2 {
    color: black;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.taskList {
  display: flex;
  z-index: 1;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  margin: 0 4px;
  padding: 0px 4px;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  row-gap: 8px;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: var(--ds-background-neutral-hovered, #091e4224) var(--ds-background-neutral, #091e420f);
  scrollbar-width: thin;
}

.jobDetails {
  margin-bottom: 20px;
}

.huntingProgress {
  margin-top: 20px;
  background-color: #343a40;
  padding: 10px;
  border-radius: 10px;
  max-width: 280px;
  color: white;
}

@media (max-width: 768px) {
  .huntingProgress {
    display: none;
  }
}


@media (max-width: 1200px) {
  .huntingProgress {
    margin-top: 10px;
  }
}

.huntingProgress h4 {
  margin-bottom: 20px;
  color: white;
  margin-top: 0px !important;
  text-align: center;
}

.huntingProgress .badge {
  /* Reduced margin to bring badges closer together */
  font-size: 1em;
}

.huntingProgress .text-center {
  margin: 0;
  padding: 0;
}

.huntingProgress .row {
  margin: 0;
}

.badge {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.progressBar {
  margin-top: 20px;
}

.card {
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  background-color: white;
  cursor: pointer;
  transition: transform 0.2s ease, opacity 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto; /* Adjust height to fit content */
  width: 100%; /* Ensure the cards fill the column width */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.approved {
  border-left: 4px solid green; /* Example color for approved */
}

.pending {
  border-left: 4px solid orange; /* Example color for pending */
}

.sideBar {
  width: 4px;
  height: 100%;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cardDetails {
  flex: 1;
}

.candidateInfo {
  display: flex;
  flex-direction: column;
}

.candidateName {
  font-weight: bold;
}

.candidateTitle,
.candidateExperience,
.candidateLastJob,
.candidateLocation {
  font-size: 0.9rem;
}

.percentageFit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.profileImage2 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.pendingCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
}

.awaitingConnection {
  font-size: 0.8rem;
  color: grey;
}



.colToggleArchive {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  z-index: 1;
}

.toggleArchive {
  position: relative;
  background-color: #333; /* Dark grey background */
  border: none; /* No border */
  width: 20%;
  color: white; /* White text */
  cursor: pointer;
  font-size: 1rem;
  padding: 5px 5px; /* Add padding for a button feel */
  border-radius: 5px; /* Rounded corners */
  z-index: 1001; /* Ensure the button is above other elements */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.toggleArchive:hover {
  background-color: #444; /* Slightly lighter grey on hover */
}

.candidateImages {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right-hand side */
  margin-top: 10px;
  // padding-right: 80px;
  @media (max-width: 1195px) {
    padding-right: 0px;
  }
  @media (max-width: 768px) {
    padding-right: 0px;
  }
}

.candidateImages > div:first-child {
  margin-right: 25px;
  font-weight: bold;
}

.imagesContainer {
  display: flex;
  flex-wrap: nowrap;
}

.profileImage,
.initialsCircle,
.pendingCircle,
.moreCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
  font-weight: bold;
  margin-left: -15px; /* Adjust margin to bring circles closer */
  border: 2px solid white;
}

.initialsCircle {
  background-color: #f0f0f0;
}

.moreCircle {
  background-color: #d3d3d3;
}

.colorKey {
  margin-top: 20px;
}

.colorKey h5 {
  margin-bottom: 10px;
}

.colorKeyContainer {
  display: flex;
  flex-wrap: wrap;
}

.colorKeyItem {
  display: flex;
  align-items: center;
  margin-right: 15px; /* Add some space between items */
}

.colorBox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}







// .nelly {
//   border-left: 4px solid #3AAE49; /* Example color for headhunter */
// }




/* Color box background colors */
.colorBox.active {
  background-color: #42BEE8;
}
.necta {
  border-left: 4px solid #42BEE8; /* Example color for find */
}

.colorBox.passive {
  background-color: #3AAE49;
}
.headhunter {
  border-left: 4px solid #3AAE49; /* Example color for headhunter */
}


.colorBox.internal {
  background-color: #D32816;
}
.internal {
  border-left: 4px solid #D32816; /* Example color for internal */
}


.colorBox.applied {
  background-color: #E86C42;
}
.applied {
  border-left: 4px solid #E86C42; /* Example color for recruiter */
}

.colorBox.recruiter {
  background-color: #BF42E8;
}

.recruiter {
  border-left: 4px solid #BF42E8;
  // Other styles specific to recruiter tasks
}

/* Ensure draggable cards work on mobile */
@media (max-width: 768px) {
  .kanbanBoard {
    
    flex-direction: column;
    overflow-x: hidden;
  }

  .columnWrapper {
    width: 100%;
    flex: 1 0 100%;
  }

  .column {
    flex-basis: auto;
    flex-grow: 1;
    width: 100%;
  }

  .taskList {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .profileImage, .initialsCircle, .pendingCircle, .moreCircle {
    width: 35px;
    height: 35px;
    margin-left: -10px;
  }
  .profileImage2{
    width: 45px;
    height: 35px;
    margin-left: -10px;
  }
}
.additional-info {
  margin-top: 10px !important;
  padding: 10px !important;
  background-color: #f7f7f7 !important; /* Light gray background */
  border: 1px solid #ddd !important; /* Light border for some definition */
  border-radius: 4px !important; /* Slightly rounded corners */
  font-size: 0.9em !important; /* Slightly smaller font size */
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scores {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
  
}
.modalBody {
  font-size: 1rem;
  color: #333;
}

.sourceTag {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: small;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 1em;
  font-weight: bold;
  color: #555;
  margin-right: 10px;
}

.sourceTagRecruiter {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: small;
  background-color: #BF42E8;
  border-radius: 5px;
  margin-top: 1em;
  font-weight: bold;
  color: #f7f7f7;
  margin-right: 10px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modalIconFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #333;
}

.icon:hover {
  color: orange;
}


.interviewTag {
  display: inline-block;
  background-color: #333; /* Light gray background */
  color: #fff; /* Dark text color */
  padding: 8px 12px; /* Padding for space around the text */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.interviewTag:hover {
  background-color: #d9d9d9; /* Slightly darker gray on hover */
  color: #000; /* Darker text color on hover */
}

.interviewTag:active {
  background-color: #bfbfbf; /* Even darker gray when clicked */
  color: #000; /* Darker text color */
}

.boxtitle {
  color:#000;
  font-weight: 500;

}