.pageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  [class~="footer"] {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  &::before {
    @media only screen and (max-width: 991px) {
      background-color: #fff !important;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 16px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    margin-top: 0px;
  }
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: unset;
  margin-top: 100px !important;
  width: 100%;
}

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 0;
}

.searchIcon {
  padding-left: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.customInput {
  [class~="form-control"] {
    background-color: #fff;
  }
}

.tableContainer {
  width: 100%;
  max-height: calc(100vh - 292px);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  [class~="table-responsive"] {
    overflow: unset;
  }
  @media only screen and (max-width: 991px) {
    max-height: calc(100vh - 226px);
  }
  @media only screen and (max-width: 454px) {
    max-height: calc(100vh - 250px);
  }
}

.tableHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 30px 15px;
  [class~="form-group"] {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 12px 24px;
  }
  @media only screen and (max-width: 454px) {
    justify-content: center;
    padding: 20px 12px;
    gap: 10px;
  }
}

.table {
  background-color: #fff;
  margin-bottom: 0;
  border-collapse: collapse;
}

.tableTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    background-color: #e3e3e3;
    transition: all 0.3s;
  }
}

.upperCase {
  text-transform: uppercase;
}

.uncontrolledDropdownWrapper {
  overflow: hidden;
}

.uncontrolledDropdown {
  list-style-type: none;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: #000000;
  &:focus,
  &:hover {
    color: #000000;
  }
}

.dropdownContent {
  margin: 0;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.dropdownMenu {
  text-align: center !important;
  transition: none !important;
  &::before {
    display: none !important;
  }
}

.hideOnMobile {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.approved {
  background-color: green;
  color: #ffffff;
}

.declined {
  background-color: red;
  color: #ffffff;
}

.pending {
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #e3e3e3;
    transition: all 0.3s;
  }
}

.desktop {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.mobile {
  @media only screen and (min-width: 601px) {
    display: none;
  }
}

.customSwitchWrapper {
  color: #000000;
  padding-right: 10px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > p {
    font-size: 14px;
    font-weight: 400;
    margin: 0 15px 0 0;
  }
  [class~="bootstrap-switch"],
  [class~="form-group"] {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width: 454px) {
    justify-content: center;
    padding-right: 0;
  }
}
