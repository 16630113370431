.root {
  text-align: left;
  & [class~="input-group"] {
    margin: 0;
  }
  &::after {
    content: "" !important;
  }
}

.input {
  width: unset !important;
}
