.sliderWrapper {
  display: flex;
  flex-direction: row;
  [class~="MuiInputBase-input"]{
    min-width: 76px !important;
  }
}

.slider {
  margin-right: 12px;
}

.input {
  width: unset !important;
}
