.card {
  color: #2c2c2c;
}

.cardbody {
  p {
    font-weight: 400;
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 20px;
  }
}

.title {
  padding-right: 35px;
  @media (min-width: 768px) {
    padding-right: 0;
  }
}

.emphasizeColor {
  color: #f96332;
}

.bgDark {
  background-color: #000000;
  color: #ffffff;
}

.editBtnDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 15px;
  background-color: #000000;
  color: #ffffff;
}

.editBtn {
  top: 36px;
  z-index: 1;
  background-color: #ffffff;
  color: #000000;
}
