.card {
  width: 450px;
  margin-bottom: 0;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.cardBody {
  padding: 0 1.25rem;
}

.titleContainer {
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  box-shadow: 0px 15px 10px -20px #111;
  background-color: #ffffff;
  z-index: 111;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
}

.subTitle {
  font-size: 14px;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 8px;
}

.content {
  min-height: 293px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
  z-index: 111;
}

.btnAddMore {
  background-color: transparent !important;
  font-size: 14px;
  padding: 0px;
  &:hover {
    box-shadow: none;
  }
}

.btnDelete {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > button {
    margin: 0px 5px 10px 0;
    padding: 0px;
    background-color: transparent;
    &:hover {
      box-shadow: none;
      background-color: transparent;
      color: #f96332;
    }
    i {
      font-size: 16px;
      color: #f96332;
    }
  }
}

.divider {
  height: 0;
  width: 50%;
  position: relative;
  margin: 30px auto 0;
  background: #cccccc;
}
