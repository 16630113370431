.root {
  text-align: left;
  & [class~="input-group"] {
    margin: 0;
  }
  &::after {
    content: "" !important;
  }
  [class~="bootstrap-switch-handle-on"] {
    background: #f96332;
    border-radius: 30px;
  }
}
