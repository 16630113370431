#root {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  // min-height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(#fff, #999); */
  background: linear-gradient(#e66465, #9198e5);
}

* {
  user-select: none;
}

#root>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app {
  overflow: hidden;
}

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

// .row>* {
//   margin: 5px;
// }

h1 {
  color: #333;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

h2 {
  color: #fff;
}

.swipe {
  // position: absolute;
  width: 100%;
  height: auto;
  min-height: 40vh;
  z-index: 1;
}

.logo {
  padding-top: 10px;
  position: relative;
  height: 80px;  // Adjust height to fit your card design
  width: auto;  // Maintain aspect ratio
  margin-bottom: 10px;
  // z-index: 2;   // Space between logo and text
}

.cardContainer {
  width: 100%;
  // max-width: 260px;
  // min-height: auto;
  // overflow:auto
  
}
.card {
  position: relative;
  width: 100%;
  height: 40vh;
  background-color: #333; /* Ensure there's a solid background */
  background-blend-mode: overlay; /* Blends the background image with the color */
  background-size: cover;
  // overflow: auto;
  background-position: center;
  box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);

}

.noMoreJobs {
  display: flex;
  background-color: #333; /* Ensure there's a solid background */
  background-blend-mode: overlay; /* Blends the background image with the color */
  flex-direction: column;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  height: 40vh; /* adjust the height to occupy 40% of the visual height */
}

.card2 {
  position: relative;
  width: 100%;
  // max-height: 60vh; /* Keep consistent with the first card */
  background-color: #333; /* Ensure there's a solid background */
  background-blend-mode: overlay; /* Blends the background image with the color */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  overflow: hidden; /* Enables scrolling */
}

// .card::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(0, 0, 0, 0.7); // Solid black with opacity for better content visibility
//   z-index: 1; // Below the content level
// }

// .overlay {
//   content: "";  
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);  // Semi-transparent black overlay
//   z-index: 1;  // Ensures the overlay is above the background image but below the content
// }

.cardContent {
  position: relative;
  overflow: hidden;
  z-index: 2; // Ensures content is above the background
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.swipe:last-of-type {

}

.buttonsWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.card h3 {
  position: relative;

  bottom: 0;
  margin: 10px;
  color: #fff;
  z-index: 2; 
}



.card h6 {
  position: relative;
  color: #fff;
  z-index: 2; 
}

.infoText {
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  color: #eee;
  animation-name: popup;
  animation-duration: 800ms;
  z-index: 2; 
}

.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 625px) {
  .buttons {
    flex-direction: column;
  } 
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0%   { transform: scale(1,1) }
  10%  { transform: scale(1.1,1.1) }
  30%  { transform: scale(.9,.9) }
  50%  { transform: scale(1,1) }
  57%  { transform: scale(1,1) }
  64%  { transform: scale(1,1) }
  100% { transform: scale(1,1) }
}