.checkboxWrapper {
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3em;
  padding: 0 !important;
  & input {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 3em;
  }
  & span {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    display: inline-block;
    padding: 7.6px 10px;
    transition: all 100ms ease-out 0s;
    user-select: none;
    border-radius: 3em;
    color: rgba(51, 51, 51, 0.6);
    background: transparent;
  }
  & input:checked ~ span:last-of-type {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px;
    color: rgb(51, 51, 51);
    padding: 7.6px 10px;
  }
  & input:not(:checked) ~ span:first-of-type {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px;
    color: rgb(51, 51, 51);
    padding: 7.6px 10px;
  }
}
