@media (min-width: 500px) {
  .feedbackContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 999999999999; /* Ensure it's on top of other elements */
  }

  .feedbackButton {
    background-image: url("https://necta.nz/static/0e3cd958c3122e8dc9e5698f84f979a4/c9d48/nelly2.avif"); /* Image of the person */
    background-size: cover;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    opacity: 0.7;
    transition: opacity 0.3s ease, width 0.3s ease, height 0.3s ease;
    cursor: pointer;
    z-index: 10000; /* Higher than container */
    border: 2px solid #fd7e14;
    position: relative;
  }

  .feedbackButton.hovered {
    opacity: 1;
    width: 80px;
    height: 80px;
  }

  .speechBubble {
    position: absolute;
    right: 90px; /* Position the bubble outside the circle */
    bottom: 15px; /* Slightly above the bottom to center it */
    background-color: black;
    color: white;
    padding: 10px 15px;
    border-radius: 10px;
    max-width: 200px;
    font-size: 14px;
    line-height: 1.2;
    z-index: 10001;
  
    /* Triangle at the right of the speech bubble, pointing towards the picture */
    &:after {
      content: '';
      position: absolute;
      bottom: 0%; /* Center the triangle vertically */
      right: -17px; /* Position the triangle at the right edge */
      transform: translateY(-50%); /* Center the triangle vertically */
      border-width: 9px;
      border-style: solid;
      border-color: transparent transparent transparent black; /* Black triangle pointing left */
    }
  }
}

/* Hide the feedback button for screen widths less than 500px */
@media (max-width: 499px) {
  .feedbackContainer {
    display: none; /* Hide the container on small screens */
  }
}
