.root {
  text-align: left;
}

.textarea {
  border: 1px solid #e3e3e3 !important;
  border-radius: 20px !important;
  &:focus {
    border: 1px solid #f96332 !important;
  }
}
