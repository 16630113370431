.imageCropperContainer {
  height: 200px;
  width: 100%;
  position: relative;
  @media (min-width: 600px) {
    height: 400px;
  }
}

.slider {
  margin-top: 15px;
  margin-bottom: 30px;
  height: 5px;
  > div:first-child {
    transform: translateY(50%);
    > div:first-child {
      transform: translateY(-50%);
    }
  }
  [class~="noUi-origin"] {
    width: 100% !important;
    left: 0 !important;
  }
}

.modalTitle {
  padding: 10px 20px 0 !important;
  justify-content: center;
  p {
    font-size: 24px;
    font-weight: bold;
    color: rgba(112, 112, 112, 1);
  }
}

.modal {
  max-width: 450px;
}

.modalBody {
  padding: 10px 20px !important;
}

.modalFooter {
  padding: 0 20px 20px !important;
}

.btn {
  width: 30% !important;
}
