.header {
  height: 81px;
  background-color: #000000;
}
.section {
  padding: 70px 0;
  position: relative;
  min-height: calc(100vh - 160px);
}
.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.oneRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
  border-bottom: 0.5px solid #000000;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    align-items: unset;
  }
}
.commonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  padding-top: 20px;
  border-top: 0.5px solid #000000;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    align-items: unset;
  }
}
.commonTitle {
  margin: 0;
  width: 130px;
  font-weight: 500;
  word-wrap: break-word;
}
.oneRowContent {
  flex: 1;
  margin: 0;
  padding: 0 15px;
}
.commonContent {
  flex: 1;
  margin: 0;
}
.commonItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
}
.commonSubtitle {
  margin: 0 0 0 10px;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}
.commonInput {
  margin: 0;
}
.commonSelect {
  margin: 0;
}

.uploadAvatar {
  display: flex;
  justify-content: center;
}
.textarea {
  > textarea {
    min-height: 120px;
  }
}
.btnAddMoreContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btnAddMore {
  background-color: transparent !important;
  font-size: 14px;
  padding: 0px;
  &:hover {
    box-shadow: none;
  }
}
.btnDelete {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > button {
    margin: 0px 5px 10px 0;
    padding: 0px;
    background-color: transparent;
    &:hover {
      box-shadow: none;
      background-color: transparent;
      color: #f96332;
    }
    i {
      font-size: 16px;
      color: #f96332;
    }
  }
}
.btnDeleteSkill {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  &:hover {
    box-shadow: none;
    background-color: transparent;
    color: #f96332;
  }
  > i {
    font-size: 16px;
    color: #f96332;
  }
}
.btnSaveContainer {
  display: flex;
  justify-content: flex-end;
}
.btnSave {
  font-size: 14px;
  > i {
    margin-right: 7px;
  }
}
.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hideBorder {
  border: none !important;
}

.deactivateTitle {
  margin: 0 0 20px;
  font-weight: 500;
}
.deactivateContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > p {
    color: red;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
}
