

.container {
  display: flex;
  flex-direction: column;
  // background-color: #fff;
  padding-bottom: 15px;
  padding-top: 100px;
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    padding-bottom: 80px;
  }
}


.input {
  border: 2px solid #E86C42; /* Border color */
  border-radius: 50%; /* Circular shape */
  outline: none;
  margin-right: 10px; /* Spacing between the radio and label text */
  cursor: pointer;
  display: inline-block;
  position: relative;
  background-color: white; /* Background color of the radio button */
}

.input:checked {
  background-color: #E86C42; /* Background color when checked */
}

// .input::after {
//   content: '';
//   display: block;
//   width: 10px;
//   height: 10px;
//   border-radius: 50%; /* Circular shape */
//   background-color: black; /* Dot color */
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }