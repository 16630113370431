.content {
  margin-top: 100px !important;
  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 180px);
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 0 !important;
  }
}

.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 651px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  flex-direction: column;
  border-radius: 0.1875rem;
  padding: 0;
  @media only screen and (min-width: 768px) {
    padding: 20px 0;
  }
}

.title {
  color: #000000;
  font-size: 1.4rem;
  font-weight: 600;
}
