.modal {
    @media only screen and (max-width: 991px) {
        [class~="modal-content"] {
            margin-bottom: 56px;
        }
    }
    [class~="modal-content"] {
        overflow: unset !important;
    }
}

.modalHeader {
    [class~="modal-title"] {
        flex: 1;
        text-align: center;
        font-weight: 600;
    }
    [class~="close"] {
        padding: 0.6rem 1rem;
    }
}

.card {
    margin-bottom: 0;
    display: block;
    @media only screen and (max-width: 500px) {
        width: inherit;
    }
}

.cardBody {
    padding: 0 1.25rem;
}

.cardDateBody {
    padding: 2rem 1.25rem;
}

.commonTitle {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 1.0571em;
    padding-left: 18px;
    height: 38px;
    line-height: 38px;
}

.commonWrapTitle {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 1.0571em;
    padding-left: 18px;
}

.downloadBtn {
    color: #f96332;
    font-size: 1.0571em;
    font-weight: 500;
    word-wrap: break-word;
    margin: 0;
    padding-left: 18px;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.buttonActionContainer {
    padding: 0 33px;
    display: flex;
    justify-content: flex-end;
}

.input {
    margin-bottom: 0;
}