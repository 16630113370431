.pageHeader {
  max-height: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
}

.pageHeaderImg {
  position: fixed;
}

.cardWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.cardItem {
  padding-bottom: 15px;
  padding-top: 15px;
  min-width: 320px;
}

.cardItemWrapper {
  display: flex;
  height: 100%;
}

.black {
  color: #000 !important;
}
