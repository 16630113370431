.modal {
  @media only screen and (max-width: 991px) {
    [class~="modal-content"] {
      margin-bottom: 56px;
    }
  }
  @media only screen and (min-width: 991px) {
    [class~="modal-content"] {
      width: calc(100vw - 400px);
    }
  }
}

.card {
  margin-bottom: 0;
  display: block;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.cardBody {
  padding: 0 1.25rem;
  overflow: auto;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.content {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.customSwitchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 6px;
  > p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  [class~="bootstrap-switch"] {
    margin-bottom: 0 !important;
  }
}

.sectionTitle {
  margin-top: 12px;
  font-weight: 600;
}

.btnEditClient {
  width: fit-content;
}

.commonSubtitle {
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: #808080;
}
.commonSubtitleFutureOpportunities {
  margin-bottom: -8px;
}
.customRadio {
  text-align: left;
  border-radius: 30px;
  display: flex;
  gap: 100px;
  [class~="form-check-label"] {
    color: #2c2c2c !important;
  }
  [class~="form-check-sign"] {
    &::before {
      border-radius: 30px !important;
    }
  }
}