//This CSS is for the whole page
.chatWrapper {
  margin-top: 77px;
  background-color: #FAF9F6;
  // @media only screen and (max-width: 991px) {
  //   max-height: calc(100vh - 1360px) !important;
  // }

  [class~='ce-chat-list-column'] {
    @media only screen and (max-width: 576px) {
      width: 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

}

// list of CSS for the list of chat boxes
.chatList {
  // box-sizing: border-box;
  min-height: 1px;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  width: 25%;
  flex: 0 0 25%;
  max-width: 25%;
  margin-left: 0%;
  right: auto;
  left: auto;
  height: calc(-85px + 100vh);
  max-height: 100vh;
  overflow: hidden scroll;
  // border-right: 1px solid rgb(175, 175, 175);
  
  
}


@media screen and (max-width: 576px) {
  .chatList {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    height: calc(-140px + 100vh);
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer */
    &::-webkit-scrollbar {
      display: none;
  }
}
}

.avatar {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.tinyavatar {
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.chatCardInformation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
  [class~='ce-chat-title-text'] {
    font-weight: 600;
  }
  [class~='ce-chat-subtitle-text'] {
    font-weight: 500;
  }
}

.haveNewMessage {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 20px;
  left: 20px;
  width: 6px;
  height: 6px;
}

//for the chat window CSS
.chatWindow {
  box-sizing: border-box;
  // background-color: #FAF9F6;
  min-height: 1px;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  width: 75%;
  flex: 0 0 75%;
  max-width: 75%;
  margin-left: 0%;
  right: auto;
  left: auto;
  flex-grow: 1;
  height: calc(-85px + 100vh);
  display: flex;      // Make this a flex container
  flex-direction: column; // Stack the children vertically
  // overflow: hidden scroll;
}

.chatWindow::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('~assets/img/messengerbk.jpg');
  background-repeat: repeat;
  background-size: 100% auto; /* This scales the image to fit the width */
  opacity: 0.2; /* This makes the image 20% transparent (80% opaque) */
  z-index: -1;
}

.chatWindow::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(250, 249, 246, 0.5); /* This is a semi-transparent off-white color */
  z-index: -1;
}


.SendMessageContainer {
  padding-left: 5px;
  padding-right: 15px;
  cursor: pointer;
}

/* Media query for screens wider than 576px */
@media screen and (max-width: 576px) {
  .chatWindow {
    flex: 1;
    width: 100%;
    max-width: 100%;
    height: calc(-140px + 100vh);
    
  }
}

.header {
  height: 81px;
  background-color: #000000;
}

.listItem {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 10px;
  // background-color: #ffffff;
  transition: all 0.2s;
  // &:hover {
  //   cursor: pointer;
  //   background-color: #e3e3e3;
  //   transition: all 0.2s;
  // }
  // &.active {
  //   background-color: #e3e3e3;
  //   transition: all 0.2s;
  // }
}

.infoDesktop {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
}
.infoMobile {
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
}
.infoTitle {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.infoSubtitle {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnBack {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // align-items: center;
  // background-color: #ffffff;
  // position: sticky;
  // top: 0;
  // width: fit-content;
  z-index: 11;
  // margin-bottom: 12px;
  > i {
    padding: 10px 20px;
    color: #000000;
    &:hover {
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 576px) {
    display: none;
  }
}

// all the job description or candidate profile CSS
.descriptionContainer {
  background-color: #c4c4c4;
  padding: 15px 70px 15px 20px;
  white-space: pre-wrap;
  position: sticky;
  top: 0;
  z-index: 11;
  // in original code, can't see why as it causes bug
  // @media only screen and (max-width: 767px) {
  //   top: 44px;
  // }
}
.descriptionHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 11px;
}
.descriptionTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.descriptionSubtitle {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
.descriptionContent {
  margin: 0;
  font-size: 14px;
  font-weight: 500px !important;
}
.descriptionIcon {
  position: absolute;
  bottom: 17px;
  right: 20px;
  i {
    font-size: 16px;
    color: #000000;
    &:hover {
      cursor: pointer;
    }
  }
}
.messageInfo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 0 10px;
}
.messageSender {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  font-weight: bold;
}
.messageTime {
  margin: 0;
  font-size: 10px;
  font-weight: 300;
}
.messageTimeRight {
  margin: 0;
  font-size: 10px;
  font-weight: 300;
  text-align: right;
}
.messageContent {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  
  
  
  
 
  padding: 10px;
  border-radius: 15px;
  // display: inline-block;
  position: relative;
  background-color: #c4c4c4;
  &:before{
    content: "";
    

  }
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-right: 5px solid #c4c4c4;
    border-left: 10px solid transparent;
    border-top: 10px solid #c4c4c4;
    border-bottom: 10px solid transparent;
    left: -8px;
    top: 3px;
       
  }
}

.messageContentRight {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-align: right;
  padding: 10px;
  border-radius: 15px;
  // display: inline-block;
  position: relative;
  background-color: #e86c42; 
  color: #ffffff;
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 5px solid #e86c42;
    border-right: 10px solid transparent;
    border-top: 10px solid #e86c42;
    border-bottom: 10px solid transparent;
    right: -8px;
    top: 3px;
  }
}



.inputContainer {
  position: fixed; /* Fix the position */
  bottom: 0; /* Stick to the bottom */
  //left: 0; /* Align to the left */
  //right: 0; /* Align to the right to take the full width */
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 10px;
  padding: 15px;
  background-color: #FAF9F6;
  z-index: 100000;
  width: 75%;
  flex-shrink: 0;
}
@media screen and (max-width: 576px) {
  .inputContainer {
    width: 100%; /* Set width to 100% for mobile screens */
  }
}

.chatListItem {
  border: none; 
  background-color: transparent;
}

.messageAvatarContainer {
  flex: 0.005;
}
.inputTextarea {
  flex: 1;
  margin: 0;
  background-color: rgb(175, 175, 175, 0.5);
  border-radius: 30px;
  height: 100%;
  input {
    height: 100%;
  }
}
.btnSend {
  margin: 0;
  // height: 50px;
  // border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}
.error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatCardChosen {
  padding: 16px 16px 12px;
  display: flex;
  background-color: #c4c4c4!important;
  cursor: pointer;
  &:hover {
    background-color: #c4c4c4!important;
  }
  position: relative;
  
}

.chatCardContainer {
  padding: 16px 16px 12px;
  display: flex;
  background-color: #FAF9F6!important;
  cursor: pointer;
  &:hover {
    background-color: #c4c4c4!important;
  }
  position: relative;
  
}

.chatCardContainerNew {
  background-color: rgba(232, 108, 66, 0.2);
  padding: 16px 16px 12px;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #c4c4c4!important;
  }
  position: relative;
}

.rightAlignedListItem {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // gap: 10px;
  // background-color: black;
  transition: all 0.2s;
  // &:hover {
  //   cursor: pointer;
  //   background-color: #e3e3e3;
  //   transition: all 0.2s;
  // }
  // &.active {
  //   background-color: #e3e3e3;
  //   transition: all 0.2s;
  // }
}

// .rightAlignedContent {
//   display: flex;
//   justify-content: flex-end;
// }

.chatListContainer {
  height: calc(100% - 120px);
  max-height: calc(100% - 130px); 
  overflow-y: auto; 
  flex-grow: 1;
  
}

.chatListContainerAi {
  height: calc(100% - 120px);
  max-height: calc(100% - 60px); 
  overflow-y: auto; 
  flex-grow: 1;
  
}

@media screen and (max-width: 576px) {
  .chatListContainer {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer */
    &::-webkit-scrollbar {
      display: none;
  }
  }
}