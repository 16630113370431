@import '../../../../../variables';


// Variables
$primary-color: $orange-bg;
$secondary-color: #343a40;
$background-color: #f8f9fa;
$hover-color: #f5f5f5;



// Mixin for box-shadow
@mixin box-shadow($x: 0, $y: 2px, $blur: 4px, $color: rgba(0, 0, 0, 0.1)) {
  box-shadow: $x $y $blur $color;
}

// Mixin for transition
@mixin transition($properties: all, $duration: 0.2s, $timing-function: ease) {
  transition: $properties $duration $timing-function;
}


.pageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  [class~='footer'] {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  @media only screen and (max-width: 991px) {
    min-height: calc(100vh - 59px);
    &::before {
      background-color: #fff !important;
    }
  }
}

.actionCard {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.equalHeight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  // height: calc(100% - 1rem);
}

.actionCardContainer {
  max-height: 745px; /* Fixed height to align with other charts */
  overflow-y: auto; /* Adds vertical scroll for overflow */
  margin-bottom: 1rem; /* Adds margin at the bottom */
}

.actionCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5; /* Highlight color on hover */
}


.actionCard .card-body {
  padding: 1rem; /* Adds padding inside the card body */
}

.actionCard .card-text {
  font-size: 1rem; /* Sets a readable font size */
  color: #333; /* Sets the text color */
}

.statcard {
  text-align: center;
  background-color: #fff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  border-radius: 12px;
}

.initialsFallback {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
}

.staticon {
  color: #343a40;
  margin-bottom: 20px;
}

.stattitle {
  font-size: 1rem;
  margin-bottom: 0rem;
  color: #6c757d;
}

.statvalue {
  font-size: 1.7rem;
  font-weight: 600;
  color: #fd7e14;
  margin-bottom: -10px;
}

.greeting {
  font-size: 2.0rem; /* Large text size */
  font-weight: 800; /* Bold text */
  color: #333; /* Darker text color */
  // text-align: center; /* Center the greeting */
  margin-bottom: 1.5rem; /* Add space below */
  // padding: 1rem; /* Padding around the text */
  // background: linear-gradient(90deg, #f3e5f5, #e8f5e9); /* Gradient background */
  // border-radius: 12px; /* Rounded corners */
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for a nice pop */
}

.greeting span {
  margin-left: 0.5rem; /* Space between text and emoji */
  font-size: 2.0rem; /* Emoji size */
}



.container {
  display: flex;
  flex-direction: column;
  // background-color: #fff;
  padding-bottom: 15px;
  padding-top: 100px;
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    padding-bottom: 80px;
  }
}

.card {
  padding: 10px;
  border-radius: 12px;
}

.columnTitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  text-align: left;
  padding: 0.75rem 1rem;
  margin-bottom: 15px;

}

.personContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

.jobContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.jobDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.jobStageBadge {
  background-color: #333; // Customize based on your design needs
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.personPicture {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.personDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  flex-grow: 1; /* Ensure left alignment */
}



.personDetails h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #000;
  font-weight: bold;
  text-align: left; /* Ensure left alignment for name */
}

.personDetails p {
  margin: 0;
  font-size: 0.8rem;
  color: #777;
  text-align: left; /* Ensure left alignment for job title */
}



.showAllButton {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: #000;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 8px;
  transition: color 0.3s ease;
}

.showAllButton:hover {
  color: #333;
}



.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: unset;
  margin-top: 110px !important;
  width: 100%;
  @media only screen and (max-width: 991px) {
    margin-top: 90px !important;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 60px !important;
  }
}

.tableHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
  gap: 10px 20px;
  @media only screen and (max-width: 500px) {
    justify-content: center;
    flex-direction: column;
  }
}

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
}

.tableContainer {
  width: 100%;
  max-height: calc(100vh - 318px);
  overflow: auto;
  [class~='table-responsive'] {
    overflow-x: unset;
  }
  @media only screen and (max-width: 991px) {
    max-height: calc(100vh - 159px);
  }
}

.table {
  background-color: #fff;
  margin-bottom: 0;
}

.titleSubTable {
  font-size: 1em !important;
  font-weight: 600 !important;
}

.titleSubTableHunt {
  font-size: 1em !important;
  font-weight: 400 !important;
}

.hiddenOnMobile {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

// disabled due to TASK 1103 Centre align column for consistency. Although this seems to fix the problem, it might have unintended results. I couldn't find another way to fix it.
// .itemTitle {
//   display: none;
//   @media only screen and (min-width: 576px) {
//     display: block;
//   }
// }

.itemCompany {
  display: none;
  @media only screen and (min-width: 576px) {
    display: block;
  }
}

.itemTitleToggle {
  // display: block;
  @media only screen and (max-width: 575px) {
    // display: block;
    cursor: pointer;
  }
}

.buttonContainer {
  @media only screen and (max-width: 441px) {
    text-align: center !important;
  }
}

.btnContent {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px !important;
  z-index: 20;
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.btnAssist {
  background-color: #f2e822;
  color: #2c2c2c;
  &:hover,
  &:focus {
    color: #2c2c2c;
    background-color: #fff75c;
  }
}
.btnAssist2 {
  background-color: #BF42E8;
  color: #f1f1f1;
  &:hover,
  &:focus {
    color: #f1f1f1;
    background-color: #BF42E8;
  }
}


.commonSelect {
  margin: 0;
}

.subTable {
  background-color: #fff;
  margin-bottom: 0;
}

.subTableHeader {
  background-color: #f1f1f1;
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}



.subTableBody {
  background-color: #f1f1f1;
}

.subTableAssist {
  background-color: #fff;
  margin-bottom: 0;
}

.subTableHeaderAssist {
  background-color: #f2e822;
  // @media only screen and (max-width: 767px) {
  //   display: none !important;
  // }
}

.iconRotateWrapper {
  cursor: pointer;
  @media only screen and (max-width: 575px) {
    display: none !important;
  }
}

.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

@media (max-width: 768px) {
  tr {
    cursor: pointer;
    /* add 'mobile-view' class */
  }
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.uncontrolledDropdownWrapper {
  overflow: hidden;
}

.dropdownItem {
  background-color: initial;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  font-weight: 400;
  padding: .25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}


.uncontrolledDropdown {
  list-style-type: none;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: #000000;
  &:focus,
  &:hover {
    color: #000000;
  }
}

.dropdownContent {
  margin: 0;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.dropdownMenu {
  text-align: center !important;
  color: #212529;
  transition: none !important;
  &::before {
    display: none !important;
  }
}

.dropdownMenu1 {
  @media only screen and (min-width: 992px) {
    left: 50px !important;
  }
}

.pdfWrapper {
  margin-top: -30px;
  width: 595px;
}

.profileName {
  margin-bottom: 4px;
  font-size: 1.5em;
}

.profileTitle {
  background-color: #f2e822;
  margin: 0;
  font-weight: 800;
  font-size: 1.4em;
}

.labelTitle {
  font-weight: 800;
}

.sectionTitle {
  background-color: #f2e822;
  margin-top: 0;
  font-size: 1.1em;
}

.experienceDescription {
  line-height: 1.61em;
  font-weight: 600;
  font-size: 1em;
}

.paragraphFontSize {
  font-size: 0.8em !important;
}

.bold {
  font-weight: 600 !important;
}
