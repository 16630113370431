.header {
  height: 81px;
  background-color: #000000;
}

.wrapper {
  margin-top: -77px;
  background-color: #000000;
}
// .section {
//   position: relative;
// }
// .contentDesktop {
//   display: flex;
//   flex-direction: row;
//   height: calc(100vh - 81px);
//   @media only screen and (max-width: 767px) {
//     display: none;
//   }
// }
// .contentMobile {
//   display: flex;
//   flex-direction: row;
//   height: calc(100vh - 81px);
//   @media only screen and (min-width: 768px) {
//     display: none;
//   }
// }

// .listDesktop {
//   width: 35%;
//   height: 100%;
//   overflow: auto;
//   min-width: 0;
//   min-height: 0;
//   border-left: 1px solid #000000;
//   border-right: 1px solid #000000;
// }
// .listMobile {
//   display: none;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   min-width: 0;
//   min-height: 0;
//   border-left: 1px solid #000000;
//   border-right: 1px solid #000000;
//   &.show {     </div>
// .listItem {
//   width: 100%;
//   padding: 10px 15px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   gap: 10px;
//   background-color: #ffffff;
//   transition: all 0.2s;
//   &:hover {
//     cursor: pointer;
//     background-color: #e3e3e3;
//     transition: all 0.2s;
//   }
//   &.active {
//     background-color: #e3e3e3;
//     transition: all 0.2s;
//   }
// }
// .avatar {
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   object-fit: cover;
// }
// .infoDesktop {
//   width: calc(100% - 70px);
//   display: flex;
//   flex-direction: column;
// }
// .infoMobile {
//   width: calc(100% - 160px);
//   display: flex;
//   flex-direction: column;
// }
// .infoTitle {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 600;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
// .infoSubtitle {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 500;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// .chatBoxDesktop {
//   width: 65%;
//   height: 100%;
//   overflow: auto;
//   min-width: 0;
//   min-height: 0;
//   border-right: 1px solid #000000;
//   position: relative;
//   &::-webkit-scrollbar {
//     display: none;
//   }
//   -ms-overflow-style: none;
//   scrollbar-width: none;
// }
// .chatBoxMobile {
//   display: none;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   min-width: 0;
//   min-height: 0;
//   border-left: 1px solid #000000;
//   border-right: 1px solid #000000;
//   position: relative;
//   &::-webkit-scrollbar {
//     display: none;
//   }
//   -ms-overflow-style: none;
//   scrollbar-width: none;
//   &.show {
//     display: block;
//   }
// }
// .btnBack {
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
//   background-color: #ffffff;
//   position: sticky;
//   top: 0;
//   width: fit-content;
//   z-index: 11;
//   margin-bottom: 12px;
//   > i {
//     padding: 15px 20px;
//     color: #000000;
//     &:hover {
//       cursor: pointer;
//     }
//   }
//   @media only screen and (min-width: 576px) {
//     display: none;
//   }
// }
// .descriptionContainer {
//   background-color: #e3e3e3;
//   padding: 15px 70px 15px 20px;
//   white-space: pre-wrap;
//   position: sticky;
//   top: 0;
//   z-index: 11;
//   @media only screen and (max-width: 767px) {
//     top: 44px;
//   }
// }
// .descriptionHeader {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   gap: 0 11px;
// }
// .descriptionTitle {
//   margin: 0;
//   font-size: 16px;
//   font-weight: 600;
// }
// .descriptionSubtitle {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 500;
// }
// .descriptionContent {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 500px !important;
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }
// .descriptionIcon {
//   position: absolute;
//   bottom: 17px;
//   right: 20px;
//   i {
//     font-size: 16px;
//     color: #000000;
//     &:hover {
//       cursor: pointer;
//     }
//   }
// }
// .chatBoxItem {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   gap: 20px;
//   padding: 10px 20px;
//   border-bottom: 1px solid #e3e3e3;
// }
// .messageInfo {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   align-items: center;
//   gap: 0 10px;
// }
// .messageSender {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 600;
// }
// .messageTime {
//   margin: 0;
//   font-size: 10px;
//   font-weight: 400;
// }
// .messageContent {
//   font-size: 14px;
//   font-weight: 500;
//   margin: 0;
// }
// .inputContainer {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 10px;
//   padding: 15px;
//   background-color: #ffffff;
//   position: sticky;
//   bottom: 0;
//   z-index: 11;
// }
// .inputTextarea {
//   flex: 1;
//   margin: 0;
//   height: 100%;
//   input {
//     height: 100%;
//   }
// }
// .btnSend {
//   margin: 0;
//   height: 70px;
//   border-radius: 20px;
//   font-size: 14px;
//   font-weight: 500;
// }
// .error {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .chatWrapper {
//   @media only screen and (max-width: 991px) {
//     max-height: calc(100vh - 132px) !important;
//   }
//   [class~="ce-settings-column"] {
//     width: 0px !important;
//     flex-basis: none !important;
//     flex: 0 0 0 !impo@media ortant;
//   }
//   [class~="ce-wrapper"] {
//     justify-content: center !important;
//   }
//   [class~="ce-chat-feed-column"] {
//     border: 1px solid #000 !important;
//     border-spacing: 0;
//     @media only screen and (max-width: 991px) {
//       height: calc(100vh - 140px) !important;
//     }
//   }
//   [class~="ce-chat-list-column"] {
//     @media only screen and (max-width: 991px) {
//       height: calc(100vh - 140px) !important;
//     }
//     @media only screen and (max-width: 576px) {
//       width: 100% !important;
//       flex: 0 0 100% !important;
//       max-width: 100% !important;
//     }
//   }
//   [class~="ce-chat-list"] {
//     border-left: 1px solid #000 !important;
//     border-top: 1px solid #000 !important;
//     border-bottom: 1px solid #000 !important;
//     border-right: none !important;
//     font-family: unset !important;
//   }
//   [class~="ce-chat-feed-container"]::-webkit-scrollbar {
//     width: 0; /* Remove scrollbar space */
//   }
//   [class~="ce-chat-list"]::-webkit-scrollbar {
//     width: 0; /* Remove scrollbar space */
//   }
//   [class~="ql-toolbar"] {
//     border: none !important;
//   }
//   [class~="quill"] {
//     border-spacing: 0;
//     border-top: 1px solid #000 !important;
//     border-bottom: 1px solid #000 !important;
//   }
//   [class~="ql-snow"] {
//     border: none !important;
//   }

//   [class~="ce-chat-feed-container"] {
//     flex-grow: 1;
//   }
//   [class~="ce-chat-feed"] {
//     display: flex;
//     flex-direction: column;
//   }
//   [class~="ce-active-chat-card"] {
//     background-color: #e3e3e3 !important;
//   }
//   [class~="ce-chat-card"] {
//     border-radius: unset !important;
//   }
//   [class~="ce-feed-container-top"] {
//     display: none;
//   }
//   [class~="ce-feed-container-bottom"] {
//     display: none !important;
//   }
// }

// .descriptionContainer {
//   background-color: #e3e3e3;
//   padding: 15px 70px 15px 20px;
//   white-space: pre-wrap;
//   position: sticky;
//   top: 0;
//   z-index: 11;
//   @media only screen and (max-width: 767px) {
//     top: 44px;
//   }
// }

// .descriptionHeader {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   gap: 0 11px;
// }
// .descriptionTitle {
//   margin: 0;
//   font-size: 16px;
//   font-weight: 600;
// }
// .descriptionSubtitle {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 500;
// }
// .descriptionContent {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 500px;
// }

// .listItem {
//   width: 100%;
//   padding: 10px 15px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   gap: 10px;
//   background-color: #ffffff;
//   transition: all 0.2s;
//   &:hover {
//     cursor: pointer;
//     background-color: #e3e3e3;
//     transition: all 0.2s;
//   }
//   &.active {
//     background-color: #e3e3e3;
//     transition: all 0.2s;
//   }
// }
// .avatar {
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   object-fit: cover;
// }
// .infoDesktop {
//   width: calc(100% - 70px);
//   display: flex;
//   flex-direction: column;
// }
// .infoMobile {
//   width: calc(100% - 160px);
//   display: flex;
//   flex-direction: column;
// }
// .infoTitle {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 600;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
// .infoSubtitle {
//   margin: 0;
//   font-size: 14px;
//   font-weight: 500;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// .chatBoxItem {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   gap: 20px;
//   padding: 10px 20px;
//   border-bottom: 1px solid #e3e3e3;
// }

// .descriptionContentWrapper {
//   display: flex;
//   flex-direction: column;
// }

// .chatCardContainer {
//   padding: 16px 16px 12px;
//   display: flex;
//   cursor: pointer;
//   &:hover {
//     background-color: #e3e3e3;
//   }
//   position: relative;
// }

// .haveNewMessage {    
//   position: absolute;
//   background-color: #f96332;
//   border-radius: 50%;
//   top: 20px;
//   left: 20px;
//   width: 6px;
//   height: 6px;
// }

// .chatCardInformation {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin-left: 12px;
//   [class~="ce-chat-title-text"] {
//     font-weight: 600;
//   }
// }
