.modal {
  @media only screen and (max-width: 991px) {
    [class~="modal-content"] {
      margin-bottom: 56px;
    }
  }
  [class~="modal-content"] {
    overflow: unset !important;
  }
}

.card {
  margin-bottom: 0;
  display: block;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
  z-index: 1111;
}

.cardBody {
  padding: 0 1.25rem;
}

.canvasContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.canvas {
  border: 1px solid #000000;
  border-style: dashed;
  width: 350px;
  height: 200px;
}

.canvasButtons {
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 15px 0 10px;
  margin-bottom: 20px;
  margin-top: 0 !important;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.content {
  min-height: 571px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  gap: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.textarea {
  > textarea {
    min-height: 100px;
  }
}

.commonTitle {
  h1 {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 800;
    word-wrap: break-word;
    font-size: 1.0571em;
    padding-left: 18px;
  }
  span {
    font-weight: 500;
  }
  margin: 1.25rem 0;
}
