

:global {
  .ql-toolbar.ql-snow {
    border: 1px solid #e3e3e3 !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;

  }

  .ql-container {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #fefcfc;
    // font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  }
}