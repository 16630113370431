.mainContainer {
  margin-left: 0; /* No margin by default */
  transition: margin-left 0.3s ease;
}

@media (min-width: 992px) {
  .sideNav {
    transform: translateX(0); /* Always visible */
  }
  .mainContainer {
    margin-left: 250px; /* Space for the side navbar */
  }
}

/* Ensuring top navbar adjusts within main container */
.topNavbar {
  width: 100%;
  position: fixed; /* Ensure it's always at the top */
  top: 0;
  left: 0; /* Align left edge with the main container */
  right: 0;
  z-index: 1031; /* Above the sideNav */
  background-color: #ccc; /* Example background color */
}

/* Content area under the top navbar */
.content {
  margin-top: 50px; /* Space for the fixed top navbar */
  padding: 20px;
}




// sidebar and navbar
@import '../../../variables2';
@import '../../../components/Mixins/now-ui-dashboard/mixins';
//adding misc:
body {
  color: $black-color;
  font-size: $font-size-base;
  font-family: $sans-serif-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: $white-color;
}
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  @include rotate-180();
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: $white-bg;

  & + .button-bar {
    margin-top: 7px;
  }

  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

// normal

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}

// .sidebar,
// .off-canvas-sidebar {
//   position: fixed;
//   top: 0;
//   height: 100%;
//   bottom: 0;
//   width: 260px;
//   left: 0;
//   z-index: 9999;
//   box-shadow: $sidebar-box-shadow;

//   .sidebar-wrapper {
//     position: relative;
//     height: calc(100vh - 75px);
//     overflow: auto;
//     width: 260px;
//     z-index: 4;
//     padding-bottom: 100px;

//     .dropdown .dropdown-backdrop {
//       display: none !important;
//     }

//     .navbar-form {
//       border: none;
//     }

//     > .nav,
//     .user .info {
//       [data-toggle="collapse"] ~ div > ul > li > a {
//         span {
//           display: inline-block;
//           @extend .animation-transition-general;
//         }

//         .sidebar-normal {
//           margin: 0;
//           position: relative;
//           transform: translateX(0px);
//           opacity: 1;
//           white-space: nowrap;
//           display: block;
//           line-height: 23px;
//           z-index: 1;
//         }

//         .sidebar-mini-icon {
//           text-transform: uppercase;
//           width: 34px;
//           margin-right: 10px;
//           margin-left: 0px;
//           font-size: 12px;
//           text-align: center;
//           line-height: 25px;
//           position: relative;
//           float: left;
//           z-index: 1;
//           display: inherit;
//           line-height: 24px;
//           color: $opacity-5;
//         }

//         i {
//           font-size: 17px;
//           line-height: 20px;
//           width: 26px;
//         }
//       }
//     }

//     [data-toggle="collapse"] ~ div > ul > li.active > a {
//       .sidebar-mini-icon {
//         color: $primary-color;
//       }
//     }
//   }

//   .navbar-minimize {
//     position: absolute;
//     right: 18px;
//     top: 2px;
//     opacity: 1;

//     .btn {
//       &,
//       &:active,
//       &:focus,
//       &:hover {
//         background-color: transparent !important;
//       }
//     }

//     @extend .animation-transition-general;
//   }
//   .logo-tim {
//     border-radius: 50%;
//     border: 1px solid #333;
//     display: block;
//     height: 61px;
//     width: 61px;
//     float: left;
//     overflow: hidden;

//     img {
//       width: 60px;
//       height: 60px;
//     }
//   }

//   .nav {
//     margin-top: 20px;
//     display: block;

//     .caret {
//       top: 14px;
//       position: absolute;
//       right: 10px;
//     }

//     li {
//       > a + div .nav li > a {
//         margin-top: 7px;
//       }

//       > a {
//         margin: 10px 15px 0;
//         border-radius: $btn-round-radius;
//         color: $white-color;
//         display: block;
//         text-decoration: none;
//         position: relative;
//         text-transform: uppercase;
//         cursor: pointer;
//         font-size: $font-size-mini;
//         padding: 10px 8px;
//         line-height: $line-height-nav-link;
//       }

//       &:first-child > a {
//         margin: 0 15px;
//       }

//       &:hover:not(.active) > a,
//       &:focus:not(.active) > a {
//         background-color: $opacity-1;
//       }

//       &:hover:not(.active) > a i,
//       &:focus:not(.active) > a i {
//         color: $white-color;
//       }

//       &.active > a:not([data-toggle="collapse"]) {
//         background-color: $white-color;
//         box-shadow: $box-shadow;
//       }

//       &.active > a[data-toggle="collapse"] {
//         background-color: $opacity-1;
//         box-shadow: none;
//         color: $white-color;

//         i {
//           color: $white-color;
//         }

//         & + div .nav .active a {
//           background-color: transparent;
//           box-shadow: none;

//           &:after {
//             content: "";
//             position: absolute;
//             background-color: $white-color;
//             border-radius: 30px;
//             box-shadow: $box-shadow;
//             color: $primary-color;
//             height: 44px;
//             width: calc(100% - 5px);
//             top: 0;
//             left: 2px;
//             z-index: 0;
//           }
//         }
//       }
//     }

//     p {
//       margin: 0;
//       line-height: 30px;
//       position: relative;
//       display: block;
//       height: auto;
//       white-space: nowrap;
//       @extend .animation-transition-general;
//     }

//     i {
//       font-size: 20px;
//       float: left;
//       margin-right: 12px;
//       line-height: 30px;
//       width: 34px;
//       text-align: center;
//       color: $opacity-5;
//       position: relative;
//     }
//   }

//   .sidebar-background {
//     position: absolute;
//     z-index: 1;
//     height: 100%;
//     width: 100%;
//     display: block;
//     top: 0;
//     left: 0;
//     background-size: cover;
//     background-position: center center;

//     &:after {
//       position: absolute;
//       z-index: 3;
//       width: 100%;
//       height: 100%;
//       content: "";
//       display: block;
//       background: #ffffff;
//       opacity: 1;
//     }
//   }

//   .logo {
//     position: relative;
//     padding: $padding-base-vertical $padding-base-horizontal;
//     z-index: 4;

//     a.logo-mini,
//     a.logo-normal {
//       @extend .animation-transition-general;
//     }

//     a.logo-mini {
//       opacity: 1;
//       float: left;
//       width: 34px;
//       text-align: center;
//       margin-left: 10px;
//       margin-right: 12px;
//     }

//     a.logo-normal {
//       display: block;
//       opacity: 1;
//       @include transform-translate-x(0px);
//     }

//     &:after {
//       content: "";
//       position: absolute;
//       bottom: 0;
//       right: 15px;
//       height: 1px;
//       width: calc(100% - 30px);
//       background-color: $opacity-5;
//     }

//     p {
//       float: left;
//       font-size: 20px;
//       margin: 10px 10px;
//       color: $white-color;
//       line-height: 20px;
//       font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//     }

//     .simple-text {
//       text-transform: uppercase;
//       padding: $padding-base-vertical 0;
//       display: block;
//       white-space: nowrap;
//       font-size: $font-size-large;
//       color: $white-color;
//       text-decoration: none;
//       font-weight: $font-weight-normal;
//       line-height: 30px;
//       overflow: hidden;
//     }
//   }

//   .logo-tim {
//     border-radius: 50%;
//     border: 1px solid #333;
//     display: block;
//     height: 61px;
//     width: 61px;
//     float: left;
//     overflow: hidden;

//     img {
//       width: 60px;
//       height: 60px;
//     }
//   }

//   &:before,
//   &:after {
//     display: block;
//     content: "";
//     opacity: 1;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//   }

//   &:after {
//     @include icon-gradient($default-color);
//     z-index: 3;
//   }

//   &[data-color="blue"] {
//     @include sidebar-color($info-color);
//   }
//   &[data-color="green"] {
//     @include sidebar-color($success-color);
//   }
//   &[data-color="orange"] {
//     @include sidebar-color($orange-color);
//   }
//   &[data-color="red"] {
//     @include sidebar-color($danger-color);
//   }
//   &[data-color="yellow"] {
//     @include sidebar-color($warning-color);
//   }

//   .user {
//     padding-bottom: 20px;
//     margin: 20px auto 0;
//     position: relative;

//     &:after {
//       content: "";
//       position: absolute;
//       bottom: 0;
//       right: 15px;
//       height: 1px;
//       width: calc(100% - 30px);
//       background-color: $opacity-5;
//     }

//     .photo {
//       width: 34px;
//       height: 34px;
//       overflow: hidden;
//       float: left;
//       z-index: 5;
//       margin-right: 10px;
//       border-radius: 50%;
//       margin-left: 23px;
//       box-shadow: $box-shadow-raised;
//       @extend .animation-transition-general;

//       img {
//         width: 100%;
//       }
//     }

//     a {
//       color: $white-color;
//       text-decoration: none;
//       padding: $padding-base-vertical 15px;
//       white-space: nowrap;
//       @extend .animation-transition-general;
//     }

//     .info {
//       > a {
//         display: block;
//         line-height: 18px;

//         > span {
//           @extend .animation-transition-general;
//           display: block;
//           position: relative;
//           opacity: 1;
//         }
//       }

//       .caret {
//         position: absolute;
//         top: 8px;
//         right: 18px;
//       }
//     }
//   }
// }

// .visible-on-sidebar-regular {
//   display: inline-block !important;
// }
// .visible-on-sidebar-mini {
//   display: none !important;
// }

// .off-canvas-sidebar {
//   .nav {
//     > li > a,
//     > li > a:hover {
//       color: $white-color;
//     }

//     > li > a:focus {
//       background: rgba(200, 200, 200, 0.2);
//     }
//   }
// }

.main-panel {
  position: relative;
  float: right;
  width: $sidebar-width;
  background-color: #fff!important;
  min-height: 100vh!important;
  
  @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  > .content {
    padding: 0 30px 30px;
    min-height: calc(100vh - 123px);
    margin-top: -30px;
    
  }

  > .navbar {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 50px;
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

.navbar.fixed-top {
  width: calc(100% - 260px);
  z-index: 10;
  right: 0;
  left: auto;
}

@media (max-width: 991px) {
  .navbar.fixed-top {
    width: 100%;
  }
}

@media (min-width: 991px) {
  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .navbar.fixed-top {
      width: calc(100% - 80px);
    }

    .navbar-minimize {
      opacity: 0;
    }

    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      z-index: 1030;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    .sidebar:hover {
      width: 260px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0);
        }
      }

      .navbar-minimize {
        opacity: 1;
      }
      .sidebar-wrapper {
        width: 260px;

        > .nav li > a p,
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span {
          @include transform-translate-x(0px);
          opacity: 1;
        }
      }
    }
  }
}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0c2646),
    color-stop(60%, #204065),
    to(#2a5788)
  );
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;

  .header {
    .title {
      color: $white-color;
    }
    .category {
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a {
        color: $white-color;
      }
    }
  }
}

.panel-header-sm {
  height: 135px;
}

.panel-header-lg {
  height: 380px;
}


//responsive

@media screen and (max-width: 991px) {
  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }

  .button-dropdown {
    display: none;
  }

  [data-notify="container"].alert {
    min-width: 400px;
  }

  #minimizeSidebar {
    display: none;
  }

  .timeline > li > .timeline-panel {
    width: 86% !important;
    float: right !important;
  }

  .timeline:before,
  .timeline > li > .timeline-badge {
    left: 5% !important;
  }

  .timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto !important;
  }

  .timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto !important;
  }

  .timeline > li:not(.timeline-inverted) > .timeline-panel:after,
  .timeline > li:not(.timeline-inverted) > .timeline-panel:before {
    @include rotate-180();
  }

  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }
    }

    .navbar-nav {
      .nav-item:first-child {
        margin-top: 10px;
      }
      .nav-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white-color;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default-color;
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default-color;
    }
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .sidebar {
    box-shadow: none;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .footer {
    .copyright {
      text-align: right;
    }
  }

  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.now-ui-icons {
        opacity: 0.5;
      }
    }
  }

  @include sidebar();
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  // .navbar.fixed-top{
  //     width: $sidebar-width;
  //     right: 0;
  //     left: auto;
  // }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .section-nucleo-icons .icons-container {
    margin: 0 0 0 auto;
  }

  .dropdown-menu .dropdown-item {
    color: inherit;
  }

  .footer {
    .copyright {
      float: right;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;

    .nav-item > .nav-link {
      margin-bottom: 5px;
    }
  }

  .user-profile [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-stats [class*="col-"] .statistics::after {
    display: none;
  }

  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }

  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }

  [data-notify="container"].alert {
    left: 10px !important;
    right: 10px !important;
    width: auto;
  }

  .card-contributions .card-stats {
    flex-direction: column;

    .bootstrap-switch {
      margin-bottom: 15px;
    }
  }

  .footer {
    .copyright {
      text-align: center;
    }
  }

  .section-nucleo-icons {
    .icons-container {
      i {
        font-size: 30px;

        &:nth-child(6) {
          font-size: 48px;
        }
      }
    }
  }

  .page-header {
    .container h6.category-absolute {
      width: 90%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  @include sidebar();

  .navbar-minimize {
    display: none;
  }

  .sidebar {
    box-shadow: none;

    .nav-open & {
      box-shadow: $sidebar-box-shadow;
    }
  }

  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-overflow-scrolling: touch;
  }
}
