.pageHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::before {
    background-color: #fff !important;
  }
  [class~="footer"] {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  &::before {
    @media only screen and (max-width: 991px) {
      background-color: #fff !important;
    }
  }
}

.content {
  @media only screen and (max-width: 991px) {
    margin-bottom: 0 !important;
  }
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: unset;
  margin-top: 100px !important;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  flex-direction: column;
  padding: 10px 0 20px;
  margin-top: 16px;
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    margin-top: 0px;
  }
}

.title {
  color: #000;
  font-weight: 600;
  font-size: 1.6em;
  margin-bottom: 0;
}

.table {
  max-height: calc(100vh - 180px);
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      th {
        font-weight: 400;
        vertical-align: middle !important; 
      }
      td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        vertical-align: middle !important; 
      }
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: calc(100vh - 238px);
  overflow: auto;
  @media only screen and (max-width: 991px) {
    max-height: calc(100vh - 218px);
    margin-bottom: 46px;
  }
}

.tableHeaderContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 30px;
  [class~="form-group"] {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 25px 25px 0;
  }
  @media only screen and (max-width: 454px) {
    justify-content: center;
    gap: 10px;
  }
}

.billPaid {
  @media only screen and (max-width: 575px) {
    background-color: green;
    color: #ffffff;
  }
}

.billOverdue {
  @media only screen and (max-width: 575px) {
    background-color: red;
    color: #ffffff;
  }
}

.stage {
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;
  }
  &:hover {
    cursor: pointer;
    background-color: #e3e3e3;
    transition: all 0.3s;
  }
}

.upperCase {
  text-transform: uppercase;
}

.hideOnMobile {
  @media only screen and (max-width: 575px) {
    display: none !important;
  }
}
