.root {
  text-align: left;
  & [class~="input-group"] {
    margin: 0;
  }
  &::after {
    content: "" !important;
  }
}

.textareaLarge {
  height: 40vh !important;
  max-height: 600px !important;
}


.disabledInput {
  background-color: #e9ecef !important;
  color: #6c757d !important;
  cursor: not-allowed;
}