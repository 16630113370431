.wrapper {
  position: relative;
  @media only screen and (max-width: 650px) {
    max-height: unset;
    min-height: unset;
  }
}

.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 651px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.content {
  margin-top: 100px !important;
  @media only screen and (max-width: 767px) {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width: 650px) {
    margin: 0 !important;
  }
}

.container {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 650px) {
    padding: 0 !important;
    margin: 0 !important;
    max-width: unset;
    min-height: calc(100vh - 139px);
  }
}

.card {
  max-width: 700px;
  color: #000000;
  padding: 15px 25px 30px;
  margin-bottom: 0;
  overflow: auto;

  @media only screen and (max-width: 767px) {
    padding: 15px 15px 30px;
  }
  @media only screen and (max-width: 650px) {
    min-height: calc(100vh - 139px);
  }
}

.addButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.addButton {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  > i {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  &::after {
    content: "";
    width: 0;
    border-top: 1px solid #000000;
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: all 0.25s;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::after {
    width: 100%;
    left: 0;
    right: 0;
    transition: all 0.25s;
  }
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media only screen and (max-width: 538px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}
.headerItem {
  position: relative;
  width: 175px;
  padding: 10px 20px;
  border-radius: 0.1875rem;
  @media only screen and (max-width: 767px) {
    width: 140px;
    padding: 10px 15px;
  }
  @media only screen and (max-width: 538px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  &::after {
    content: "";
    opacity: 0;
    width: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    transition: all 0.3s ease-in-out;
  }
  &.active {
    &::after {
      width: 50%;
      opacity: 100%;
      transition: all 0.3s ease-in-out;
    }
  }
}
.backgroundPending {
  color: #ffffff;
  background-color: #e86c42;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
  }
  &::after {
    border: 1px solid #e86c42;
  }
}
.backgroundApproved {
  color: #ffffff;
  background-color: #42bee8;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
  }
  &::after {
    border: 1px solid #42bee8;
  }
}
.backgroundPaid {
  color: #ffffff;
  background-color: #bf42e8;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
  }
  &::after {
    border: 1px solid #bf42e8;
  }
}
.headerItemTitle {
  display: flex;
  flex-direction: column;
  > p {
    margin: 0;
    font-weight: 500;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 538px) {
    flex-direction: row;
    gap: 5px;
  }
}
.headerItemPrice {
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}
.verticalDiviver {
  height: 70px;
  border-left: 1px solid #000000;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0 0;
  @media only screen and (max-width: 767px) {
    padding: 15px 0 0;
  }
}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 15px;
  }
}
.filterLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.filterLeftItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
}
.filterRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  > button {
    margin: 0;
  }
}
.filterButton {
  background-color: #e3e3e3;
  color: #000000;
  &:hover,
  &:focus {
    background-color: #f96332;
  }
}

.listContainer {
  height: 330px;
  overflow: auto;
  min-width: 0;
  padding-top: 10px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.listItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ededed;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 3px;
  transition: all 0.3s;
  &:hover {
    background-color: #cccccc;
    transition: all 0.3s;
  }
}
.listItemHover {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ededed;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 3px;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    background-color: #cccccc;
    transition: all 0.3s;
  }
}
.listItemLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.timesheetName {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.timesheetId {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
  }
}
.timesheetDate {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
  }
}
.listItemRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.timesheetPrice {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  text-align: right;
  @media only screen and (max-width: 430px) {
    font-size: 18px;
  }
}
.timesheetStatus {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  @media only screen and (max-width: 430px) {
    font-size: 12px;
  }
}
.datePaid {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  @media only screen and (max-width: 430px) {
    font-size: 12px;
  }
}

.noItemMessage {
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.buttonDelete {
  color: #ffffff;
  background-color: #e86c42;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    background-color: #e86c42;
    transition: all 0.3s;
  }
}

.buttonSave {
  color: #ffffff;
  background-color: #42bee8;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    background-color: #42bee8;
    transition: all 0.3s;
  }
}

.buttonSubmit {
  color: #ffffff;
  background-color: #bf42e8;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    opacity: 0.8;
    transition: all 0.3s;
    background-color: #bf42e8;
    color: #ffffff;
  }
}

.footer {
  @media only screen and (max-width: 650px) {
    display: none;
  }
}
