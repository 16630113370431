.body {
    background-color: #ffffff;
    box-shadow: 0px 15px 10px -20px #111;
    padding: 20px; 
}
  
  .header {
    background-color: #ffffff;
    box-shadow: 0px 15px 10px -20px #111;
    z-index: 111;



  }
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;

    color: rgb(112, 112, 112);
    padding: 15px 0 0px;

  }

  .clientTable {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .tableHeader {
    background-color: #f8f9fa;
  }
  
  .tableRow {

    &:hover {
      background-color: #e9ecef;
    }
  }
  
  .tableRow td {
    vertical-align: middle;
  }
  
  .tableRow td:last-child {
    text-align: right;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }