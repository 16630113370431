@import '../../_variables.scss';
@import './_modals.scss';

.card {
  margin-bottom: 0;
  display: block;
  width: 50%;
  @media only screen and (max-width: 500px) {
    width: 100vw;
    height: 85vh;
  }
}

.large {
  max-width: 80vw;
  max-height: 80vh;
  @media only screen and (max-width: 500px) {
    max-width: 100vw;
    max-height: 85vh;
  }
}

.modal-scrollable-content {
  max-height: 75vh; /* Or any other value that fits your design */
  overflow-y: auto; /* Enables vertical scrolling */
}


  
  .cardBody {
    padding: 0 1.25rem;
    overflow-y: auto; 
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: rgb(112, 112, 112);
    padding: 15px 0 10px;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    box-shadow: 0px 15px 10px -20px #111;
    z-index: 111;
  }
  
  .content {
    // min-height: 571px;
    // display: flex;
    // flex-direction: column;
    justify-content: center;
  }
  
  .buttonContainer {
    margin-top: 10px;
    // padding: 15px 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    // box-shadow: 0px -15px 10px -20px #111;
  }
  
//   .rateTitle {
//     color: rgba(112, 112, 112, 1);
//   }
  
//   .sliderRate {
//     margin: 6px 12px 0;
//   }
  
//   .textarea {
//     > textarea {
//       min-height: 100px;
//     }
//   }
  
//   .uploadCvWrapper {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     margin-left: 8px;
//     gap: 8px;
//   }
  
//   .uploadTitle {
//     color: rgb(112, 112, 112);
//     margin: 0;
//     font-weight: 500;
//     word-wrap: break-word;
//     font-size: 0.9571em;
//     padding-left: 0;
//     padding-right: 8px;
//   }
  
//   .uploadFile {
//     border: 1px solid #cccccc;
//     padding: 10px;
//     color: black !important;
//     border-radius: 30px;
//     flex: 1;
//   }
  
//   .btnDownload {
//     margin: 0;
//     padding: 10px 8px;
//   }
  
//   .btnDeleteWrapper {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//   }
  
//   .btnDelete {
//     width: fit-content !important;
//   }
  
//
// Modals
// Now Ui Kit Design element Dialogs
// --------------------------------------------------
.modal-content {

  border-radius: $border-radius-small;
  border: none;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 0;
    padding-left:   24px;

    & button{
        position: absolute;
        right: 27px;
        top: 30px;
        outline: 0;
    }
    .title{
        margin-top: 5px;
        margin-bottom: 0;
    }
  }
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 16px;
    padding-left:   24px;
    line-height: 1.9;
  }
  // Footer (for actions)
  .modal-footer {
    border-top: none;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    -webkit-justify-content: space-between; /* Safari 6.1+ */
    justify-content: space-between;

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;
      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }

  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: rgba(0,0,0,0.3);
}

.modal{

    &.modal-mini{
        p{
            text-align: center;
        }

        .modal-dialog{
            max-width: 255px;
            margin: 0 auto;
        }

        .modal-profile{
            width: 70px;
            height: 70px;
            background-color: $white-color;
            border-radius: 50%;
            text-align: center;
            line-height: 5.7;
            box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.3);

            i{
                color: $primary-color;
                font-size: 21px;
            }

            &[class*="modal-profile-"]{
                i{
                    color: $white-color;
                }
            }

            &.modal-profile-primary{
                background-color: $primary-color;
            }

            &.modal-profile-danger{
                background-color: $danger-color;
            }

            &.modal-profile-warning{
                background-color: $warning-color;
            }

            &.modal-profile-success{
                background-color: $success-color;
            }

            &.modal-profile-info{
                background-color: $info-color;
            }
        }

        .modal-footer{
            button{
                text-transform: uppercase;

                &:first-child{
                    opacity: .5;
                }
            }
        }
    }

    &.modal-default{
        @include modal-colors($white-color, $black-color);
    }

    &.modal-primary{
        @include modal-colors($primary-color, $white-color);
    }

    &.modal-danger{
        @include modal-colors($danger-color, $white-color);
    }

    &.modal-warning{
        @include modal-colors($warning-color, $white-color);
    }

    &.modal-success{
        @include modal-colors($success-color, $white-color);
    }

    &.modal-info{
        @include modal-colors($info-color, $white-color);
    }

    &.show.modal-mini .modal-dialog{
        -webkit-transform: translate(0,30%);
        -o-transform: translate(0,30%);
        transform: translate(0,30%);
    }

    .modal-header .close{
        color: $danger-color;
        text-shadow: none;

        &:hover,
        &:focus{
            opacity: 1;
        }
    }
}
