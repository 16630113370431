.page {
  flex-direction: column;
  // @media only screen and (max-width: 500px) {
  //   padding: 0 20px;
  // }
}

.pageContent {
  overflow: auto;
  min-width: 0;
  min-height: 0;
  width: fit-content !important;
  border-radius: 0.1875rem;
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  @media only screen and (max-width: 500px) {
    width: 100% !important;;
  }
}

.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Ensures the container takes the full height of the viewport
}
