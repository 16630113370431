.card {
  width: 450px;
  margin-bottom: 0;
  padding: 10px 20px;
  .btn {
    width: 30%;
  }
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.cardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: rgba(112, 112, 112, 1);
  margin-top: 10px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 20px;
}

.content {
  min-height: 388px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rateTitle {
  color: rgba(112, 112, 112, 1);
}

.sliderRate {
  margin: 6px 20px 0;
}

.section {
  text-align: left;
}

.subField {
  margin-top: 20px;
}

.customRadio {
  text-align: left;
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  [class~="form-check-label"] {
    color: #2c2c2c !important;
  }
  [class~="form-check-sign"] {
    &::before {
      border-radius: 30px !important;
    }
  }
}
