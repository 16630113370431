.pageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  &::before {
    background-color: #fff !important;
  }
  justify-content: center;
  [class~='footer'] {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  @media only screen and (max-width: 991px) {
    min-height: calc(100vh - 59px);
    &::before {
      background-color: #fff !important;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 15px;
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    padding-bottom: 80px;
  }
}

// @media (max-width: 770px) {
//   .container {
//     width: 100%;
//     box-sizing: border-box; /* ensures padding and border are included in width */
//     padding: 0;
//     margin: 0;
//   }
// }

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: unset;
  margin-top: 110px !important;
  width: 100%;
  @media only screen and (max-width: 991px) {
    margin-top: 120px !important;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 120px !important;
    width: 100vw;
  }
}

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
}

.table {
  max-height: calc(100vh - 180px);
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      th {
        font-weight: 400;
      }
    }
  }
}

.tableContainer {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
  // @media only screen and (max-width: 991px) {
  //   // max-height: calc(100vh - 340px);
  //   // margin-bottom: 50px;
  // }
  @media only screen and (max-width: 575px) {
    max-height: calc(100vh - 360px);
    margin-bottom: 50px;
  }
}

.tableHeaderContainer {
  // width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  [class~="form-group"] {
    margin-bottom: 0;
  }
  // [class~="react-datepicker__input-container"] {
  //   margin-left: 12px !important;
  // }
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
  }
}

.leftAlignDropdowns {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start; /* Aligns children elements to the start of the container */
}

.btnContent {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.btnClearFilter {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: fit-content;
  margin-left: 20px;
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.upperCase {
  text-transform: uppercase;
}

.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.searchIcon {
  cursor: pointer;
}

.subTable {
  border-top: none !important;
}

.collapseSubTable {
  display: flex;
  padding: 16px 64px;
  @media screen and (max-width: 768px) {
    padding: 12px 6px;
    width: 100vw;
  }
}

.dNoneMd {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.collapseInformation {
  width: 85%;
  display: flex;
  list-style: none;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  li {
    text-align: left;
    h2 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 6px;
      color: rgba(0, 0, 0, 0.4);
    }
    p {
      overflow-wrap: break-word;
      font-size: 14px;
    }
  }
}

.collapseButton {
  display: flex;
  align-items: center;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: #000000;
  &:focus,
  &:hover {
    color: #000000;
  }
}

.policyFilter {
  &:hover {
    cursor: pointer;
    background-color: #e3e3e3;
    transition: all 0.3s;
  }
}

.dropdownContent {
  margin: 0;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.dropdownMenu {
  text-align: center !important;
  transition: none !important;
  &::before {
    display: none !important;
  }
}

.uncontrolledDropdown {
  list-style-type: none;
}

.iconRotateWrapper {
  cursor: pointer;
  @media only screen and (max-width: 575px) {
    display: none !important;
  }
}

.rateFilter {
  width: 10%;
  i {
    font-size: 12px;
  }
  &:hover {
    cursor: pointer;
    background-color: #e3e3e3;
    transition: all 0.3s;
  }
}

.subTitle {
  text-align: center;
  color: #000;
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

.filterWrapper {
  display: flex;
  align-items: center!important; // Add this
  flex-direction: row;
  justify-content: space-between;
  gap: 10px; 
  // @media (max-width: 600px) { /* adjust as needed */
  //   flex-direction: column;
  // }
}

@media (max-width: 770px) {
  .filterWrapper {
    display: block;
  }
  
  .filterWrapper :global(>*) {
    margin: 10px;
  }
  .filtersButton {
    display: block;
  }
}

  

@media (min-width: 771px) {
  .filterWrapper {
    display: flex; /* This was block, changed to flex */
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  .filtersButton {
    display: none;
  }
}}


