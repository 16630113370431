@import '../../../../_variables.scss';
@import '../../../Mixins/mixins';


.other {
  @media only screen and (min-width: 992px) {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
      // Ensure the background gradient is applied
      &::before {
          background: linear-gradient(#f2e822 0%, #000 80%) !important;
      }
      text-align: left; // Align text to the left
      padding-left: 15px; // Add some padding to push content from the edge
      background-color: #999;
      border-top: none;
      display: block !important;
      height: 100% !important;
      max-height: none !important;
      overflow-y: visible;
      position: fixed;
      right: 0;
      text-align: left;
      top: 0;
      -webkit-transform: translate3d(300px, 0, 0);
      transform: translate3d(300px, 0, 0);
      transition: all .5s cubic-bezier(.685,.0473,.346,1);
      visibility: visible;
      width: 300px;
      z-index: 1032;
    
}
  
  .nav-item {
    width: 100%;
    text-align: left;
}
  .navbar-nav {
    align-items: flex-start!important;
    font-size: .7142em;
    line-height: 1.625rem;
    padding: .5rem .7rem;
    padding-top: 0.5rem;
    padding-right: 0.7rem;
    padding-bottom: 0.5rem;
    padding-left: 0.7rem;
    text-transform: uppercase;
  }
  .navbar-nav .nav-link {
      padding-left: 0; // Adjust padding to ensure alignment
      padding-right: 0; // Adjust padding to ensure alignment
  }

  // Ensure Dropdown items are also aligned properly
  .dropdown {
      text-align: left; // Align dropdown text to the left
      right: auto; // Reset any right alignment if present
      left: 15px; // Adjust this based on your layout requirements
  }
}

.navbar-collapse {
  @media only screen and (max-width: 991px) {
    &::before {
      background: linear-gradient(#f2e822 0%, #000 80%) !important;
    }
  }
}

.dropdownMenu::-webkit-scrollbar {
  display: none;
} 

// .dropdownItem {
//   color: #212529!important;
// }

.dropdownMenu {
  -ms-overflow-style: none;
  scrollbar-width: none;
  transform: translate3d(0px, 40px, 0px)!important;
  @media only screen and (max-width: 991px) {
    height: auto !important;
    transform: translate3d(0px, 0px, 0px)!important;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    height: 125px;
    margin: 0 1rem 15px;
    margin-top: 0px;
    margin-right: 1rem;
    margin-bottom: 15px;
    margin-left: 1rem;
    overflow-y: scroll;
    padding-top: 0;
    transition: none;
    width: auto;
  }
}

.logoWord {
  font-size: 1.2em;
}

.offersIcon {
  position: relative;
}

.offersDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}

.navbarMenu {
  position: relative;
}

.navbarMenuDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 9px;
  width: 5px;
  height: 5px;
}

.hideOnMobile {
  @media only screen and (max-width: 575px) {
    display: none !important;
  }
}

@import '../../../../_variables.scss';

.other {
  @media only screen and (min-width: 992px) {
    display: none;
  }
}

.navbar-collapse {
  @media only screen and (max-width: 991px) {
    &::before {
      background: linear-gradient(#f2e822 0%, #000 80%) !important;
    }
  }
}

.dropdownMenu::-webkit-scrollbar {
  display: none;
}

// .dropdownMenu {
//   -ms-overflow-style: none;
//   scrollbar-width: none;
//   @media only screen and (max-width: 991px) {
//     height: auto !important;
//   }
// }

.logoWord {
  font-size: 1.2em;
}

.bgBlack {
  background-color: transparent !important;
  color: #fff !important;
  
  

}

.bgBlack span {
  background-color: #fff !important; /* changed to #fff (white) */
  color: #fff !important;
}

.signOutDesktop {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.activeDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}

.timesheetNotify {
  position: absolute;
  top: -6px;
  left: -3px;
  font-size: 0.7em;
  color: #f96332;
  font-weight: 700;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberOfPendingTimesheets {
  height: 15px;
}

.navbarMenu {
  position: relative;
}

.navbarMenuDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 9px;
  width: 5px;
  height: 5px;
}

.offersDot {
  position: absolute;
  background-color: #f96332;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
}

.button {
  padding: 11px 22px!important;
}

.dropdown-item {
  background-color: initial;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  font-weight: 400;
  padding: .25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  font-size: .7571em;
  margin-top: 5px;
  padding-bottom: .6rem;
  padding-top: .6rem;
  transition: all .15s linear;
  @media screen and (max-width: 991px){
    color: white!important;
    text-align: left;
  }
}

.navbar-nav, .UncontrolledDropdown {
  position: relative; // Ensures dropdown alignment is relative to nav-item
}

.dropdown-menu {
  display: block;
  will-change: transform;
  // right: 0;  // Adjust this if the dropdown appears misaligned
  // left: auto; // Usually, setting this to 'auto' aligns it properly but might need specific adjustments
}

.dropdown-menu:before {
  content: "";
  position: absolute;
  top: -8px;  // Positions the caret just above the dropdown
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 10px solid #fff;  // Adjust the color to match the dropdown background
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  display: none;  // Caret is initially hidden
}

.show > .dropdown-menu:before {
  display: block;  // Display the caret when the dropdown is shown
}
.navbar{
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  min-height: 53px;
  // margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

  a{
      vertical-align: middle;

      &:not(.btn):not(.dropdown-item){
          color: $white-color;
      }
  }

  p{
      display: inline-block;
      margin: 0;
      line-height: 21px;
      font-weight: inherit;
      font-size: inherit;
  }

  .navbar-nav{
    align-items: center;
    font-size: .7142em;
    line-height: 1.625rem;
    // padding: .5rem .7rem;
    text-transform: uppercase;
      &.navbar-logo{
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 49px;
          top: -4px;
      }

      .nav-link.btn{
          padding: $padding-btn-vertical $padding-btn-horizontal;
          font-size: 11px;

          &.btn-lg{
              padding: $padding-large-vertical $padding-large-horizontal;
          }

          &.btn-sm{
              padding: $padding-small-vertical $padding-small-horizontal;
          }
      }
      .now-ui-icons.icon-badge + p {
        margin-left: 3px;
      }
    
      .now-ui-icons {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
      }
    
      .now-ui-icons.icon-badge {
        top: 4px;
        font-size: 16px;
      }
    

      .navbar-nav .now-ui-icons.icon-badge + p {
        margin-left: 3px;
      }

      .nav-link:not(.btn){
          text-transform: uppercase;
          font-size: $font-size-mini;
          padding: $padding-base-vertical $padding-base-horizontal;
          line-height: $line-height-nav-link;

          i.fab + p,
          i.now-ui-icons + p{
              margin-left: 3px;
          }
          i.icon-badge + p {
            margin-left: 3px;
          }

          i.fab,
          i.now-ui-icons{
              font-size: 18px;
              position: relative;
              top: 3px;
              text-align: center;
              width: 21px;
          }

          i.now-ui-icons{
              top: 4px;
              font-size: 16px;
          }

          &.profile-photo{
              .profile-photo-small{
                  width: 27px;
                  height: 27px;
              }
          }

          &.disabled{
              opacity: .5;
              color: $white-color;
          }
      }

      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active{
          background-color: $opacity-2;
          border-radius: $border-radius-small;
      }
  }

  .logo-container{
      width: 27px;
      height: 27px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 50%;
      border: 1px solid transparent;
  }

  .navbar-brand{
      text-transform: uppercase;
      font-size: $font-size-small;
      padding-top: $padding-base-vertical;
      padding-bottom: $padding-base-vertical;
      line-height: $line-height-nav-link;
  }

  .navbar-toggler{
      width: 37px;
      height: 27px;
      outline: 0;
      color: white!important;
      cursor: pointer;
      background-color: initial;
      border: 1px solid transparent;
      border-radius: .25rem;
      font-size: 1.25rem;
      line-height: 1;
      padding: .25rem .75rem;

      &.navbar-toggler-left{
          position: relative;
          left: 0;
          padding-left: 0;
      }

      .navbar-toggler-bar.middle-bar{
        width: 17px;
        transition: width .2s linear;
      }

      &:hover{
          & .navbar-toggler-bar.middle-bar{
              width: 22px;
          }
      }
  }

  .button-dropdown{
      .navbar-toggler-bar:nth-child(2){
          width: 17px;
      }
  }

  &.navbar-transparent{
      background-color: $transparent-bg !important;
      box-shadow: none;
      color: $white-color;
      padding-top: 20px !important;
  }

  &.bg-white:not(.navbar-transparent){
      a:not(.dropdown-item):not(.btn){
          color: $default-color;

          &.disabled{
              opacity: .5;
              color: $default-color;
          }
      }

      .button-bar{
          background: $default-color;
      }

      .nav-item.active .nav-link:not(.btn),
      .nav-item .nav-link:not(.btn):focus,
      .nav-item .nav-link:not(.btn):hover,
      .nav-item .nav-link:not(.btn):active{
          background-color: $opacity-gray-3;
      }

      .logo-container{
          border: 1px solid $default-color;
      }
  }
}

.bg-default{
  background-color: $default-color !important;
}

.bg-primary{
  background-color: $primary-color !important;
}

.bg-info{
  background-color: $info-color !important;
}

.bg-success{
  background-color: $success-color !important;
}

.bg-danger{
  background-color: $danger-color !important;
}

.bg-warning{
  background-color: $warning-color !important;
}

.bg-white{
  background-color: $white-color !important;
}

.navbar{
  p{
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute{
	  position: absolute;
	  width: 100%;
	  padding-top: 10px;
	  z-index: 1029;
  }

  .navbar-toggler{
    vertical-align: middle;

    &.navbar-toggler-left{
      top:0;
    }

    &.navbar-toggler-right{
      padding-right: 0;
      top: 8px;
    }
  }
}

.navbar{
  p{
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute{
	  position: absolute;
	  width: 100%;
	  padding-top: 10px;
	  z-index: 1029;
  }

  .navbar-toggler{
    vertical-align: middle;

    &.navbar-toggler-left{
      top:0;
    }

    &.navbar-toggler-right{
      padding-right: 0;
      top: 8px;
    }
  }
}


@media screen and (max-width: 991px){
  @include navbar-collapse();

  .profile-photo .profile-photo-small{
      margin-left: -2px;
  }

  [class*="navbar-toggleable-"] .navbar-collapse{
      width: 300px;
  }

  .button-dropdown{
      display: none;
  }

  .section-nucleo-icons .container .row > [class*="col-"]:first-child{
      text-align: center;
  }

  .footer{
      .copyright{
          text-align: right;
      }
  }

  .section-nucleo-icons .icons-container{
      margin-top: 65px;
  }

  .section-images{
      height: 500px;
      max-height: 500px;

      .hero-images-container{
          max-width: 500px;
      }

      .hero-images-container-1{
          right: 10%;
          top: 68%;
          max-width: 269px;
      }

      .hero-images-container-2{
          right: 5%;
          max-width: 135px;
          top: 93%;
      }
  }

  .nav-open{
      .navbar-collapse{
          @include transform-translate-x(0px);
      }

      .wrapper{
          @include transform-translate-x(-150px);
      }

      .navbar-translate{
          @include transform-translate-x(-300px);
      }

      .menu-on-left{
          .navbar-collapse{
              @include transform-translate-x(0px);
          }

          .navbar-translate{
              @include transform-translate-x(300px);
          }

          .wrapper{
              @include transform-translate-x(150px);
          }

          #bodyClick{
              right: auto;
              left: 300px;
          }
      }
  }
}


//put whole file in:
@media screen and (max-width: 991px) {
  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }

  .button-dropdown {
    display: none;
  }

  [data-notify="container"].alert {
    min-width: 400px;
  }

  #minimizeSidebar {
    display: none;
  }

  .timeline > li > .timeline-panel {
    width: 86% !important;
    float: right !important;
  }

  .timeline:before,
  .timeline > li > .timeline-badge {
    left: 5% !important;
  }

  .timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto !important;
  }

  .timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto !important;
  }

  .timeline > li:not(.timeline-inverted) > .timeline-panel:after,
  .timeline > li:not(.timeline-inverted) > .timeline-panel:before {
    @include rotate-180();
  }

  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }
    }

    .navbar-nav {
      .nav-item:first-child {
        margin-top: 10px;
      }
      .nav-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white-color;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default-color;
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default-color;
    }
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .sidebar {
    box-shadow: none;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .footer {
    .copyright {
      text-align: right;
    }
  }

  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.now-ui-icons {
        opacity: 0.5;
      }
    }
  }

  @include sidebar();
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }


  .navbar .navbar-toggle {
    display: none;
  }

  // .navbar.fixed-top{
  //     width: $sidebar-width;
  //     right: 0;
  //     left: auto;
  // }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .section-nucleo-icons .icons-container {
    margin: 0 0 0 auto;
  }

  .dropdown-menu .dropdown-item {
    color: inherit;
  }

  .footer {
    .copyright {
      float: right;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;

    .nav-item > .nav-link {
      margin-bottom: 5px;
    }
  }

  .user-profile [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-stats [class*="col-"] .statistics::after {
    display: none;
  }

  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }

  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }

  [data-notify="container"].alert {
    left: 10px !important;
    right: 10px !important;
    width: auto;
  }

  .card-contributions .card-stats {
    flex-direction: column;

    .bootstrap-switch {
      margin-bottom: 15px;
    }
  }

  .footer {
    .copyright {
      text-align: center;
    }
  }

  .section-nucleo-icons {
    .icons-container {
      i {
        font-size: 30px;

        &:nth-child(6) {
          font-size: 48px;
        }
      }
    }
  }

  .page-header {
    .container h6.category-absolute {
      width: 90%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  @include sidebar();

  .navbar-minimize {
    display: none;
  }

  .sidebar {
    box-shadow: none;

    .nav-open & {
      box-shadow: $sidebar-box-shadow;
    }
  }

  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-overflow-scrolling: touch;
  }
}
