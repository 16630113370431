.mainContainer {
  height: calc(100vh - 110px);  /* Remove the top navigation height */
  width: calc(100vw - 00px);   /* Remove the sidebar width */
  overflow: hidden;
  position: relative;
  margin-top: 110px;  /* Push content down by the height of the top navigation */
  margin-left: 0px; /* Push content right by the width of the sidebar */
}

.scrollIcon {
  position: fixed;
  bottom: 20px;
  left: 90px;
  width: 20px;
  height: 20px;
  z-index: 10000;
}

.profileImageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .profilePhoto {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #fff; // Optional border for a polished look
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  }
}
.profileCardBody {
  padding: 20px;
  background-color: #f8f9fa; // Light background for contrast
  border-radius: 8px;
}

.profileName {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #343a40;
}

.profileDetails {
  margin-bottom: 15px;
  
  .profileTitle {
    font-size: 18px;
    color: #343a40;
    margin-bottom: 10px;
  }

  .contactInfo p {
    margin: 5px 0;
    font-size: 14px;
    color: #495057;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
}

.profileBio {
  margin-bottom: 20px;

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #6c757d;
    line-height: 1.5;
  }
}


.sidebar {
  position: fixed;
  right: -300px;  /* Initially hide the sidebar outside the viewport */
  top: 0;
  width: 500px;
  height: 100vh;
  // background-color: white;
  // box-shadow: -3px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  display: flex;
  z-index: 9999999999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: right 2.4s ease-in-out;  /* Add a smooth transition */
}

.sidebarOpen {
  right: 0;  /* Slide the sidebar into view */
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}

.sidebar img {
  margin-bottom: 20px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
