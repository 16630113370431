.root {
  text-align: left;
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  &[class~="has-danger"] {
    &::after {
      display: none;
    }
    [class~="react-select__control"] {
      border-color: #ffcfcf;
    }
  }
  & [class~="react-tagsinput"] {
    padding: 0 16px;
    width: 100%;
    ::placeholder {
      color: #a3a3a3;
    }
  }
}

.reactTagsInput {
  padding: 10px 0;
  margin-bottom: 0;
  line-height: 0;
  color: #2c2c2c;
  width: 100%;
}
