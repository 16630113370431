.root {
  text-align: left;
  &[class~="has-danger"] {
    &::after {
      display: none;
    }
    [class~="react-select__control"] {
      border-color: #ffcfcf;
    }
  }
}
