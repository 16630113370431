.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 651px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.content {
  margin-top: 100px !important;
  @media only screen and (max-width: 767px) {
    margin: 0 !important;
    margin-bottom: 0 !important;
  }
}

.container {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    padding: 0 !important;
    margin: 0 !important;
    max-width: unset;
    min-height: calc(100vh - 139px);
  }
}

.card {
  max-width: 800px;
  color: #000000;
  padding: 15px 25px;
  margin-bottom: 0;
  overflow: auto;

  @media only screen and (max-width: 767px) {
    padding: 15px 15px 30px;
    min-height: calc(100vh - 139px);
    border-radius: 0;
  }
}

.title {
  color: #000000;
  font-size: 1.4rem;
  font-weight: 600;
}

.table {
  max-height: calc(100vh - 180px);
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      th {
        font-weight: 400;
      }
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: calc(100vh - 275px);
  overflow: auto;

  @media only screen and (max-width: 575.98px) {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
  }
}

.mobileHideTable {
  @media only screen and (max-width: 575.98px) {
    display: none;
  }
}
