.card {
  margin-bottom: 100px;
  display: block;
  width: 100%;
  height: 100%;
}

.cardBody {
  padding: 0 1.25rem;
  overflow: auto;
}


.title-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: relative;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
  width: 100%; /* Ensure the container takes full width */
}


.title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  text-align: center;
  margin: 0; /* Ensure no extra margin is causing misalignment */
  padding: 0; /* Remove default padding */
  flex-grow: 1; /* Allow title to take up remaining space */
}

.btn {
  margin: 0 10px; /* Optional: add some spacing between buttons */
}

.btnDelete {
  margin-left: auto; /* Pushes the delete button to the far right */
}

.bottomNavBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #f8f9fa; /* Light background color */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.bottomNavBar button {
  margin: 0 15px; /* Adds space between the buttons */
}

@media (max-width: 768px) {
  .bottomNavBar {
    flex-direction: column;
  }

  .bottomNavBar button {
    margin: 10px 0; /* Adjust spacing for smaller screens */
    width: 80%; /* Make buttons wider on mobile */
  }
}

.btn:first-child {
  left: 0; /* Position Back/Cancel button on the left */
}

.btn:last-child {
  right: 0; /* Position Load Template button on the right */
}

.spacer {
  width: 100px; /* Adjust the width to match the button width */
  height: 1px;
}

.content {
  min-height: 571px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  // justify-content: center;
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.sliderHourlyRate {
  margin: 4px 12px 6px;
}

.textarea {
  > textarea {
    min-height: 100px;
  }
}

.btnDeleteWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btnDelete {
  width: fit-content !important;
}


.progressContainer {
  width: 100%;
  margin-bottom: 20px;
}

.progressBar {
  height: 24px; // Increase the height for better visibility
  border-radius: 12px;
  background-color: #f1f1f1;
  position: relative;
}

.stageLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.stageLabel {
  font-size: 14px;
  font-weight: normal;
  color: #b0b0b0;
  position: relative;
  text-align: center;
}

.activeStage {
  font-weight: bold;
  color: #000;
}

.progress  {
  background-color: #BF42E8; // Color for the filled portion
}