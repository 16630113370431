.modal {
    @media only screen and (max-width: 991px) {
        [class~="modal-content"] {
            margin-bottom: 56px;
        }
    }
    [class~="modal-content"] {
        max-height: 90vh !important;
        overflow: auto;
    }
    @media only screen and (min-width: 991px) {
        max-width: 600px;
    }
}

.modalHeader {
    [class~="modal-title"] {
        flex: 1;
        text-align: center;
        font-weight: 600;
    }
    [class~="close"] {
        padding: 0.6rem 1rem;
    }
}

.card {
    margin-bottom: 0;
    display: block;
    @media only screen and (max-width: 500px) {
        width: inherit;
    }
}

.cardBody {
    padding: 0 1.25rem;
}

.cardDateBody {
    padding: 2rem 1.25rem;
}

.commonTitle {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 1.0571em;
    padding-left: 18px;
    margin-top: auto;
    margin-bottom: auto;
    @media only screen and (max-width: 500px) {
        line-height: unset;
    }
}

.editItem {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 1.0571em;
    margin-top: auto;
    margin-bottom: auto;
    height: fit-content;
}

.descriptionEdit {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 1.0571em;
}

.editTitle {
    color: #000;
    margin: 0;
    font-weight: 600;
    word-wrap: break-word;
    font-size: 1.0571em;
    cursor: pointer;
    i {
        vertical-align: middle;
    }
}

.commonWrapTitle {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 1.0571em;
    padding-left: 18px;
}

.downloadBtn {
    color: #f96332;
    font-size: 1.0571em;
    font-weight: 500;
    word-wrap: break-word;
    margin: 0;
    padding-left: 18px;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.buttonActionContainer {
    padding: 0 33px;
    display: flex;
    justify-content: flex-end;
}

.input {
    margin-top: auto;
    margin-bottom: auto;
}

.termModal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }