.card {
    width: 450px;
    margin-bottom: 0;
    padding: 10px 20px;
    .btn {
      width: 30%;
    }
    @media only screen and (max-width: 500px) {
      width: inherit;
    }
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: rgba(112, 112, 112, 1);
    margin-bottom: 20px;
  }
  
  .content {
    min-height: 119px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  