.page {
  flex-direction: column;
  // @media only screen and (max-width: 500px) {
  //   padding: 0 20px;
  // }
}

.pageContent {
  overflow: auto;
  min-width: 0;
  min-height: 0;
  width: fit-content !important;
  border-radius: 0.1875rem;
  // box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
  max-height: calc(100vh - 88px);
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  min-height: 100vh; /* Full viewport height */
  padding: 20px; /* Padding to ensure some spacing around */
  @media only screen and (max-width: 500px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 768px) {
    max-height: calc(100vh - 160px);
    margin: auto !important;
  }
}

.pageContainer {
  padding: 0 !important;
}
