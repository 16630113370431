.pageHeader {
  @media only screen and (max-width: 991px) {
    min-height: unset !important;
  }
}

.content {
  @media only screen and (min-width: 992px) {
    margin-top: 100px !important;
  }
  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 180px);
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width: 575.98px) {
    margin-top: 0 !important;
  }
}

.header {
  height: 81px;
  background-color: #000000;
  @media only screen and (min-width: 575.98px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  // max-width: unset !important;
  // @media only screen and (min-width: 768px) {
  //   padding: 20px 0;
  // }
  // @media only screen and (min-width: 992px) {
  //   padding: 20px 0;
  // }
  @media only screen and (max-width: 991px) {
    max-width: unset !important;
    margin: 0 !important;
    height: 100%;
  }
}

.card {
  color: #000000;
  padding: 15px 25px;
  margin-bottom: 0;
  overflow: auto;
  border-radius: 0;
  @media only screen and (max-width: 767px) {
    padding: 15px 15px 30px;
    min-height: calc(100vh - 139px);
    border-radius: 0;
  }
}

.title {
  color: #000000;
  font-size: 1.4rem;
  font-weight: 600;
}

.table {
  max-height: calc(100vh - 180px);
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-size: 1.15em;
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      th {
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: calc(100vh - 439px);
  overflow: auto;
  @media only screen and (max-width: 991px) {
    max-height: calc(100vh - 397px);
  }
  @media only screen and (max-width: 767px) {
    max-height: calc(100vh - 483px);
  }
  @media only screen and (max-width: 575.98px) {
    max-height: calc(100vh - 437px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
  }
}

.mobileHideTable {
  @media only screen and (max-width: 575.98px) {
    display: none;
  }
}

.reportTitle {
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  @media only screen and (max-width: 575.98px) {
    font-size: 1.3em;
  }
}
.reportSubTitle {
  @media only screen and (max-width: 575.98px) {
    font-size: 1.2em;
  }
}
.datePickerWrapper {
  text-align: left;
  &[class~="has-danger"] {
    &::after {
      display: none;
    }
    [class~="react-select__control"] {
      border-color: #ffcfcf;
    }
  }
  [class~="react-datepicker-popper"] {
    z-index: 999;
  }
  [class~="react-datepicker__month-text--keyboard-selected"],
  [class~="react-datepicker__day--keyboard-selected"],
  [class~="react-datepicker__day--selected"] {
    background-color: #f96332;
  }
  [class~="react-datepicker__header"] {
    background-color: #ffffff;
    color: #f96332;
  }
  [class~="react-datepicker__current-month"],
  [class~="react-datepicker__day-name"] {
    color: #f96332;
  }
  [class~="react-datepicker__year-read-view--down-arrow"],
  [class~="react-datepicker__navigation-icon"]::before {
    border-color: #f96332;
  }
}
.datePickerInput {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  color: #2c2c2c;
  height: auto;
  line-height: normal;
  font-size: 0.8571em;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  box-shadow: none;
  padding: 10px 18px 10px 18px;
  &:focus {
    border: 1px solid #f96332;
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
  }
  &::placeholder {
    color: #888888;
    opacity: 0.8;
  }
  &:-ms-input-placeholder {
    color: #888888;
    opacity: 0.8;
  }
  &::-ms-input-placeholder {
    color: #888888;
    opacity: 0.8;
  }
}
