/* Base styles */
.headerContainer {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  p{
    font-weight: 400;
  }
}

.jobContainer {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.jobCard {
  margin-bottom: 20px;
}
.h6 {
  color: black;
  padding-left: 0px!important;
  
}

.pastContractor {
  background-color: #e9ecef;
}

.jobHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobInfo {
  flex: 1;
}

.jobTitle {
  font-weight: bold;
  font-size: 1.2em;
}

.jobSubTitle {
  font-size: 1em;
  color: #666;
}

.jobDetails {
  display: flex;
  align-items: center;
}

.jobDetails div {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.candidateCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #332f2d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 1em;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.buttons button {
  margin: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .jobHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .candidateCircle {
    display: none;
  }

  .jobDetails {
    display: none;
  }

  .jobTitle {
    font-size: 1em;
  }

  .jobSubTitle {
    font-size: 0.9em;
  }

  .buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .jobTitle {
    font-size: 0.9em;
  }

  .jobSubTitle {
    font-size: 0.8em;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  padding-top: 150px;
}

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
}
