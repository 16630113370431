.main,
.summary,
.responsibility {
  p {
    font-weight: 400;
  }
}

.main {
  li {
    font-weight: 400;
    line-height: 1.61em;
    font-size: 1.2em;
  }
}

.editBtnDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 15px;
  background-color: #000000;
  color: #ffffff;
}

.editBtn {
  top: 36px;
  z-index: 1;
  background-color: #000000;
  color: #ffffff;
}

.navExItems {
  flex-wrap: nowrap;
  a {
    margin-right: 0px !important;
  }
  @media only screen and (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.navExItem {
  @media only screen and (max-width: 575px) {
    margin-bottom: 12px;
    a {
      padding: 10px 10px !important;
      min-width: 70px !important;
    }
  }
}

.commonTitle {
  h1 {
    color: rgb(112, 112, 112);
    margin: 0;
    font-weight: 800;
    word-wrap: break-word;
    font-size: 1.2em;
  }
  h5 {
    font-weight: 500;
    font-size: 1.2em;
  }
  margin: 1.25rem 0;
}
