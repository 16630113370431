.modal {
    @media only screen and (max-width: 991px) {
        [class~="modal-content"] {
            margin-bottom: 56px;
        }
    }
    [class~="modal-content"] {
        overflow: unset !important;
    }
    z-index: 2000;
}

.card {
    margin-bottom: 0;
    z-index: 2000;
    display: block;
    @media only screen and (max-width: 500px) {
        width: inherit;
    }
}

.cardBody {
    padding: 0 1.25rem;
}

.cardDateBody {
    padding: 2rem 1.25rem;
}

.title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: rgb(112, 112, 112);
    padding: 15px 0 10px;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    box-shadow: 0px 15px 10px -20px #111;
    z-index: 111;
}

.content {
    min-height: 571px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttonContainer {
    margin-top: 10px;
    padding: 15px 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    box-shadow: 0px -15px 10px -20px #111;
}

.textarea {
    > textarea {
        min-height: 100px;
    }
}
.commonTitle {
    color: rgb(112, 112, 112);
    margin: 0;
    width: 100%;
    font-weight: 500;
    word-wrap: break-word;
}
