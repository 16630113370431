.card {
  margin-bottom: 0;
  @media only screen and (max-width: 500px) {
    width: inherit;
  }
}

.modal {
  @media only screen and (min-width: 991px) {
    [class~="modal-content"] {
      width: calc(100vw - 400px);
    }
  }
}

.cardBody {
  padding: 0 1.25rem;
  overflow: auto;
  @media only screen and (max-width: 575px) {
    max-height: calc(100vh - 120px);
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(112, 112, 112);
  padding: 15px 0 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -20px #111;
  z-index: 111;
}

.content {
  min-height: 571px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section {
  text-align: left;
}

.dateContainer {
  margin-bottom: 10px;
}

.customCheckbox {
  border-radius: 30px;
  [class~="form-check-label"] {
    color: #2c2c2c !important;
  }
  [class~="form-check-sign"] {
    &::before {
      border-radius: 30px !important;
    }
  }
}

.textarea {
  > textarea {
    min-height: 320px;
  }
}

.disabled {
  cursor: not-allowed;
  input {
    background-color: #e3e3e3;
    color: #e3e3e3;
    cursor: not-allowed;
    &:focus {
      color: #e3e3e3;
    }
  }
}

.buttonContainer {
  margin-top: 10px;
  padding: 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -15px 10px -20px #111;
}

.btnAddMore {
  background-color: transparent !important;
  font-size: 14px;
  padding: 0px;
  &:hover {
    box-shadow: none;
  }
}

.btnDelete {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > button {
    margin: 0px 5px 10px 0;
    padding: 0px;
    background-color: transparent;
    &:hover {
      box-shadow: none;
      background-color: transparent;
      color: #f96332;
    }
    i {
      font-size: 16px;
      color: #f96332;
    }
  }
}

.divider {
  height: 0;
  width: 50%;
  position: relative;
  margin: 30px auto 0;
  background: #cccccc;
}
